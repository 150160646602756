import { NavLink } from "react-router-dom";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";

export default function DataPolicy() {
  return (
    <>
      <section id="breadcrumb" className="bg-[#F5F5FC] py-1">
        <div className="container mx-auto">
          <div className="flex justify-between">
            <div>Data Protection Policy</div>
            <div>Home / Data Protection Policy</div>
          </div>
        </div>
      </section>

      <section id="latest-blog">
        <div className="container mx-auto">
          <div className="flex space-x-10 pt-5">
            <div className="w-2/3">
              <h1 className="font-primary text-3xl font-bold uppercase text-primary">
                OUR DATA PROTECTION POLICY
              </h1>
              <p className="my-5 font-text text-2xl text-textColor">
                How Do We Collect, Use, and Protect Your Personal Data
              </p>
              <img src="/images/Our-Data-Privacy-Policy.png" alt="" />
              <div>
                <p className="py-3 font-bold">
                  Public Speaking Academy – Personal Data Protection Notice
                </p>
                <p className="pb-5">
                  The Public Speaking Academy Pte Ltd (“PSA”) is committed to
                  ensuring the protection and respect of the privacy of our
                  students/clients. We recognise the need for careful and open
                  management of your personal data as part of your trust in us.
                  In line with this, this Personal Data Protection Notice
                  (“Notice”) sets out the basis upon which PSA may collect, use,
                  disclose or otherwise process personal data of our
                  students/clients in accordance with the Personal Data
                  Protection Act (“PDPA”) (you can find out more about the
                  obligations under the PDPA through their guide here).
                </p>
                <p className="pb-5">
                  This Notice is to assist you in understanding how PSA
                  maintains the protection and respect of the personal data in
                  our possession or under our control, including personal data
                  in the possession of organisations which we have engaged to
                  collect, use, disclose or process personal data for our
                  purposes. We have developed this Notice to help you understand
                  how we collect, use, disclose, process, protect and retain
                  your personal data that is in our possession.
                </p>
                <p className="pb-5">
                  We encourage an open, frank, and amicable platform – if you
                  have any concerns about the collection, use, and/or disclosure
                  of your personal data with PSA, please reach out to our Data
                  Protection Officer through
                  contact@publicspeakingacademy.com.sg.
                </p>
                <p className="pb-5 font-bold">DEFINITIONS & TERMS</p>
                <p className="pb-5 font-bold">
                  1 - This section sets out the the definitions of key terms as
                  used in this Notice:
                </p>
                <p className="pb-5">
                  <strong>“students/client(s)”</strong> means an individual or
                  individuals who (a) has/have contacted PSA through any means
                  to find out more about any goods or services we provide, (b)
                  may, or has/have, entered into a contract with PSA for the
                  supply of any goods or services by us, or (c) has/have
                  signed-up, enrolled or is attending any courses, lessons,
                  assessments, examinations or any other programmes/events with
                  PSA, on behalf of themselves or others; and
                </p>
                <p className="pb-5">
                  <strong>“personal data”</strong> means data, whether true or
                  not, about a student/client who can be identified: (a) from
                  that data; or (b) from that data and other information to
                  which we have or are likely to have access.
                </p>
                <p className="pb-5">
                  <strong>2 - What type of personal data do we collect?</strong>{" "}
                  Depending on the nature of your interaction with PSA, some
                  examples of personal data which we may collect from you
                  include, but are not limited to, name, identification
                  information such as your NRIC number, contact information such
                  as your address, email address or telephone number,
                  nationality, gender, date of birth, marital status,
                  photographs and other audio-visual information, employment
                  information and financial information such as credit card
                  numbers, debit card numbers or bank account information.
                </p>
                <p className="pb-5">
                  <strong>3 -</strong> Other terms used in this Notice shall
                  have the meanings given to them in the PDPA (where the context
                  so permits).
                </p>
                <p className="pt-5 pb-4">
                  <strong>
                    COLLECTION, USE, AND PROCESSING OF PERSONAL DATA{" "}
                  </strong>
                </p>
                <p className="pb-5">
                  <strong>
                    4 - Collection of personal data: PSA generally does not
                    collect your personal data unless{" "}
                  </strong>
                </p>
                <p className="pb-5">
                  it is provided to PSA voluntarily by you directly or via a
                  third-party who has been duly authorised by you to disclose
                  your personal data to us (your “authorised representative”)
                  after (i) you (or your authorised representative) have been
                  notified of the purposes for which the data is collected,
                  used, and/or disclosed and (ii) you (or your authorised
                  representative) have provided written consent to the
                  collection and usage of your personal data for those purposes,
                  or
                </p>
                <p className="pb-5">
                  collection, use or disclosure of personal data without consent
                  is permitted or required by the PDPA or other laws.
                </p>
                <p className="pb-5">
                  <strong>5 - </strong>PSA shall seek your consent before
                  collecting any additional personal data and before using your
                  personal data for a purpose which has not been notified to you
                  (except where permitted or authorised by law e.g. when the
                  personal data is publicly available).
                </p>
                <p className="pb-5">
                  <strong>6 - </strong>When will we collect personal data from
                  you? Generally, we collect your personal data when you, among
                  others:
                </p>
                <p className="pb-5">
                  register, enrol or sign up for any courses, lessons,
                  assessments, examinations or any other programmes conducted,
                  operated and/or managed by PSA (which include requests to be
                  placed on any wait-lists), whether as a student yourself or
                  through parents registering for their children;
                </p>
                <p className="pb-5">
                  express an interest or request for more information on any
                  courses, lessons, assessments, examinations or any other
                  programmes conducted, operated and/or managed by PSA; or
                </p>
                <p className="pb-5">
                  visit our website or other online/social media platforms, and
                  leave behind your contact information.
                </p>
                <p className="pb-5">
                  <strong>7. How do we use your personal data?</strong> PSA may
                  collect and use your personal data for any or all of the
                  following purposes:
                </p>
                <p className="pb-5">
                  performing obligations in the course of or in connection with
                  our provision of the goods and/or services offered by PSA, and
                  requested by you;
                </p>
                <p className="pb-5">
                  sending you updates and/or managing the administrative aspects
                  about the courses, lessons, assessments, examinations or any
                  other programmes conducted, operated and/or managed by PSA,
                  and to allow PSA to provide these services to you;
                </p>
                <p className="pb-5">
                  verifying your identity in facilitating the administration,
                  management or conduct of the courses, lessons, assessments,
                  examinations or any other programmes by PSA;
                </p>
                <p className="pb-5">
                  responding to, handling, and processing queries, requests,
                  applications, complaints, and feedback from you pertaining to
                  the courses, lessons, assessments, examinations or any other
                  programmes by PSA;
                </p>
                <p className="pb-5">
                  administering and/or managing your relationship with us
                  (including, but not limited to, responding to enquiries, the
                  mailing of correspondence, statements or notices which could
                  involve the disclosure of certain personal data to bring about
                  delivery of the same);
                </p>
                <p className="pb-5">
                  processing payment or credit transactions;
                </p>
                <p className="pb-5">
                  complying with any applicable laws, regulations, codes of
                  practice, guidelines, or rules, or to assist in law
                  enforcement and investigations conducted by any governmental
                  and/or regulatory authority;
                </p>
                <p className="pb-5">
                  any other purposes for which you have provided the
                  information;
                </p>
                <p className="pb-5">
                  transmitting to any unaffiliated third parties including our
                  third-party service providers and agents, and relevant
                  governmental and/or regulatory authorities, whether in
                  Singapore or abroad, for the aforementioned purposes; and
                </p>
                <p className="pb-5">
                  any other incidental business purposes related to or in
                  connection with the above.
                </p>
                <p className="pb-5">
                  <strong>8 - </strong>When will we disclose your personal data?
                  PSA may disclose your personal data:
                </p>
                <p className="pb-5">
                  where such disclosure is required for performing obligations
                  in the course of or in connection with our provision of the
                  goods or services requested by you (e.g. such as security
                  clearance for a specific training venue where required);
                </p>
                <p className="pb-5">
                  to third party service providers, agents and other
                  organisations we have engaged to perform any of the functions
                  listed in clause 5 above for us;
                </p>
                <p className="pb-5">
                  where required to do so by law i.e. we may disclose personal
                  data about you to the relevant authorities or to law
                  enforcement agencies where required.
                </p>
                <p className="pb-5">
                  <strong>9 - </strong>The purposes listed in the above clauses
                  may continue to apply even in situations where your
                  relationship with us (for example, pursuant to a contract) has
                  been terminated or altered in any way, for a reasonable period
                  thereafter (including, where applicable, a period to enable us
                  to enforce our rights under a contract with you).
                </p>
                <img
                  className="pb-5"
                  src="/images/Our-Data-Privacy-Policy-2.png"
                  alt=""
                />
                <p className="pb-5">
                  <strong>WITHDRAWAL OF YOUR CONSENT</strong>
                </p>
                <p className="pb-5">
                  <strong>10 - </strong>The consent that you provide for the
                  collection, use and disclosure of your personal data will
                  remain valid until such time it is being withdrawn by you in
                  writing. You may withdraw consent and request PSA to stop
                  collecting, using and/or disclosing your personal data for any
                  or all of the purposes listed above by submitting your request
                  in writing or via email to PSA at the contact details provided
                  below.
                </p>
                <p className="pb-5">
                  <strong>11 - </strong>Upon receipt of your written request to
                  withdraw your consent, PSA may require reasonable time
                  (depending on the complexity of the request, its impact on our
                  relationship with you, or the conduct of any courses, lessons,
                  assessments, examinations or any other programmes) for your
                  request to be processed and for us to notify you of the
                  consequences of us acceding to the same, including any legal
                  consequences which may affect your rights and liabilities to
                  us. In general, we shall seek to process your request within
                  fifteen (15) business days of receiving it.
                </p>
                <p className="pb-5">
                  <strong>12 - </strong>Whilst we respect your decision to
                  withdraw your consent, please note that depending on the
                  nature and scope of your request, we may not be in a position
                  to continue providing our goods or services to you and we
                  shall, in such circumstances, notify you before completing the
                  processing of your request. For example, without your personal
                  data, we are unable to arrange any relevant training programme
                  for you or your child. Should you decide to cancel your
                  withdrawal of consent, please inform us in writing in the
                  manner described in clause 8 above.
                </p>
                <p className="pb-5">
                  <strong>13 - </strong>Please note that withdrawing consent
                  does not affect our right to continue to collect, use and
                  disclose personal data where such collection, use and disclose
                  without consent is permitted or required under applicable
                  laws.
                </p>
                <p className="pb-5">
                  <strong>ACCESS TO AND CORRECTION OF PERSONAL DATA </strong>
                </p>
                <p className="pb-5">
                  <strong>14 - </strong>If you wish to make (a) an access
                  request for access to a copy of the personal data which we
                  hold about you or information about the ways in which we use
                  or disclose your personal data, or (b) a correction request to
                  correct or update any of your personal data which we hold
                  about you, you may submit your request in writing or via email
                  to PSA at the contact details provided below.
                </p>
                <p className="pb-5">
                  <strong>15 - </strong>Please note that a reasonable fee (for
                  the handling and processing of your request) may be charged
                  for an access request. If so, we will inform you of the fee
                  before processing your request.
                </p>
                <p className="pb-5">
                  <strong>16 - </strong>PSA will respond to your written request
                  as soon as reasonably possible. In general, our response will
                  be within thirty (30) business days. Should we not be able to
                  respond to your request within thirty (30) days after
                  receiving your request, we will inform you in writing within
                  thirty (30) days of the time by which we will be able to
                  respond to your request. If we are unable to provide you with
                  any personal data or to make a correction requested by you, we
                  shall generally inform you of the reasons why we are unable to
                  do so (except where we are not required to do so under the
                  PDPA).
                </p>
                <p className="pb-5">
                  <strong>17 - </strong>If, for any reason whatsoever, you are
                  uncomfortable with the manner or extent of collection, use
                  and/or disclosure of your personal data by PSA, we encourage
                  you to inform us through our contact point below so that a
                  reasonable and mutually acceptable solution to your concerns
                  can be found.
                </p>

                <p className="pb-5">
                  <strong>PROTECTION OF PERSONAL DATA </strong>
                </p>
                <p className="pb-5">
                  <strong>18 - </strong>To safeguard your personal data from
                  unauthorised access, collection, use, disclosure, copying,
                  modification, disposal or similar risks, we have introduced
                  appropriate administrative, physical and technical measures
                  such as up-to-date antivirus protection, and disclosing
                  personal data to authorised persons only on a need-to-know
                  basis.
                </p>
                <p className="pb-5">
                  <strong>19 - </strong>You should be aware, however, that no
                  method of transmission over the Internet or method of
                  electronic storage is completely secure. While security cannot
                  be guaranteed, PSA strives to protect the security of your
                  information and are constantly reviewing and enhancing our
                  information security measures.
                </p>
                <p className="pb-5">
                  <strong>20 - </strong>If a data breach occurs (e.g. personal
                  data held by us is lost, stolen, inadvertently disclosed to an
                  external party, or accidentally published), our team will
                  assess the nature of the breach to determine if it is
                  notifiable breach (as per the Data Breach Notification
                  obligation under the PDPA). We will notify you within a
                  reasonable timeframe should such a breach occur. Our team
                  reiterates our commitment in maintaining an open, amicable,
                  and expeditious channel in ensuring the protection of your
                  personal data.
                </p>
                <p className="pb-5">
                  <strong>ACCURACY OF PERSONAL DATA</strong>
                </p>
                <p className="pb-5">
                  <strong>21 - </strong>PSA generally relies on personal data
                  provided by you (or your authorised representative) in the
                  provision and conduct of our services (such as PSA’s courses,
                  programmes or lessons). In order to ensure that your personal
                  data is current, complete and accurate, please update us if
                  there are changes to your personal data by informing PSA in
                  writing or via email at the contact details provided below
                  (such as a change in your contact number or email address).
                </p>
                <p className="pb-5">
                  <strong>RETENTION OF PERSONAL DATA </strong>
                </p>
                <p className="pb-5">
                  <strong>22 - </strong>PSA may retain your personal data for as
                  long as it is necessary to fulfil the purpose for which it was
                  collected, or as required or permitted by applicable laws.
                </p>
                <p className="pb-5">
                  <strong>23 - </strong>PSA will cease to retain your personal
                  data, or remove the means by which the data can be associated
                  with you, as soon as it is reasonable to assume that such
                  retention no longer serves the purpose for which the personal
                  data was collected, and is no longer necessary for legal or
                  business purposes.
                </p>
                <p className="pb-5">
                  <strong>
                    TRANSFERS OF PERSONAL DATA OUTSIDE OF SINGAPORE
                  </strong>
                </p>
                <p className="pb-5">
                  <strong>24 - </strong>PSA generally does not transfer your
                  personal data to countries outside of Singapore. However, if
                  we do so, we will obtain your consent for the transfer to be
                  made and we will take steps to ensure that your personal data
                  continues to receive a standard of protection that is at least
                  comparable to that provided under the PDPA.
                </p>
                <p className="pb-5">
                  <strong>CONTACT POINT & DATA PROTECTION OFFICER</strong>
                </p>
                <p className="pb-5">
                  <strong>25 - </strong>You may contact
                  contact@publicspeakingacademy.com.sg if you have any enquiries
                  or feedback on our personal data protection policies and
                  procedures, or if you wish to make any request.
                </p>
                <p className="pb-5">
                  <strong>EFFECT OF NOTICE AND CHANGES TO NOTICE </strong>
                </p>
                <p className="pb-5">
                  <strong>26 - </strong>This Notice applies in conjunction with
                  any other notices, contractual clauses and consent clauses
                  that apply in relation to the collection, use and disclosure
                  of your personal data by PSA.
                </p>
              </div>
            </div>
            <div className="mb-10 w-1/3 rounded-lg border border-dashed border-gray-400 px-5 pt-5">
              <Sidebar />
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
