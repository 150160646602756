import Header from "../components/Header";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import { NavLink } from "react-router-dom";

export default function GroupClassesAdults() {
  return (
    <>
      <section id="breadcrumb" className="bg-[#F5F5FC] py-1">
        <div className="container mx-auto">
          <div className="flex flex-row justify-between mx-3">
            <div>WORLD CHAMPION CERTIFICATION PROGRAMME</div>
            <div>
              Home / Courses & Programs / Public Speaking for Adults / Regular,
              Weekly Classes
            </div>
          </div>
        </div>
      </section>

      <section id="block-1" className="mx-3">
        <div class="container mx-auto py-20">
          <div class="flex flex-col lg:flex-row pt-5 space-x-10">
            <div class="w-full lg:w-2/3">
              <h1 class="font-primary text-3xl font-bold text-primary uppercase">
                World Champion Certification Programme (Public Speaking)
              </h1>
              <h2 class="font-primary text-2xl font-bold text-primary uppercase py-5">
                Regular Group Public Speaking Training for Adults (Weekly)
              </h2>
              <div>
                <img src="/images/holiday-programme-1.png" alt="" />
                <p class="py-5 font-text text-lg">
                  Find yourself stumped, doubtful and nervous when asked to
                  deliver a high-stakes business presentation? Having trouble
                  communicating and motivating the team you have just been
                  assigned to lead? Or simply looking for a refreshing take on
                  effective communications and networking confidence skills?
                </p>
                <p class="py-5 font-text text-lg">
                  We recognise the modern struggle that adults and working
                  professionals face in public speaking and communications –
                  indispensable skills in the modern workplace. We believe in
                  lifelong learning and continuous skills upgrading, especially
                  in ensuring that you are not left behind in your fast-paced
                  workplace. True to this, the Public Speaking Academy has
                  designed Singapore’s first World Champion public speaking
                  course for adults, tailored to meet the contemporary workplace
                  demands. The programme is designed by our Training team, as
                  led by Darren Tay and registered with the Ministry of
                  Education.
                </p>
                <div className="aspect-video">
                  <iframe
                    class="w-full h-full"
                    src="https://www.youtube.com/embed/CQnUd0r8SYo"
                    title="World Champion Certification Program - Course Intro"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                </div>
                <p className="pt-5 pb-5 text-xl font-bold">
                  Introducing the World Champion Certification Programme (Public
                  Speaking)!
                </p>
                <p className="text-lg font-text">
                  The World Champion Certification Programme is a public
                  speaking course in Singapore designed for adults / working
                  professionals by our first Singaporean World Champion of
                  Public Speaking, Darren Tay, Founder, Managing Director and
                  Master Trainer at the Public Speaking Academy. Tailored for
                  adults and working professionals, the World Champion
                  Certification Programme will guide you through an
                  introduction, exploration, and enhancement of public speaking
                  expertise and techniques through regular, group training
                  sessions. This engaging and holistic public speaking training
                  programme will help you with:
                </p>
                <ul className="p-5 list-disc list-outside">
                  <li className="text-lg font-text">
                    Communications Foundation-Building – introduction to
                    necessary foundation skills to enhance your communication
                    and start building your public speaking learning journey
                    (e.g. body language, pronunciation, enunciation, vocal
                    variety)
                  </li>
                  <li className="text-lg font-text">
                    Expertise and Techniques for the Modern Communication
                    Settings – learn and master relevant and effective
                    communication skills to facilitate the growth of your
                    potential as a confident and charismatic communicator (e.g.
                    interview skills, PowerPoint presentation skills, persuasive
                    speech)
                  </li>
                  <li className="text-lg font-text">
                    Constructive and Safe Training Platform – providing a safe
                    space for you to grow as a public speaker or effective
                    communicator through practice opportunities, constructive
                    feedback and immediate feedback implementation
                  </li>
                  <li className="text-lg font-text">
                    Our Learning Methodology – Constructively-Challenging &
                    Sufficiently-Safe Learning
                  </li>
                </ul>

                <p class="pb-5 font-text text-lg">
                  <b>Who should join?</b> The World Champion Certification
                  Programme is perfect for both beginners having their first
                  foray into the public speaking training journey and advanced
                  speakers looking to continue their
                  communications/presentations skills development.
                </p>
                <p class="pb-5 font-text text-lg">
                  <b>What can I expect?</b> The programme is conducted as
                  weekly, regular group training sessions, with greater emphasis
                  on an encouraging and engaging learning environment. Instead
                  of a one-way lecture-style, our competent and passionate
                  training team delivers a hands-on approach for optimal
                  learning of public speaking skills and techniques – we believe
                  that learning is best achieved through a maximising
                  combination of theory and practice. We provide our students
                  with the printed handouts and video recordings of their
                  practice speech deliveries, maximising their learning by
                  providing them with an opportunity to evaluate their
                  performance in class and identify key areas of improvement
                  moving forward.
                </p>
                <p class="pb-5 font-text text-lg">
                  <b>How big is the class?</b> We keep our class sizes small
                  (maximum 12 students) to ensure that each learner receives
                  dedicated focus and attention to their learning and
                  development from our trainers. You will get the opportunity to
                  review your learning each class in front of a sufficiently
                  challenging group size.
                </p>
                <p class="pb-5 font-text text-lg">
                  We provide our learners with a sufficiently safe, but still
                  constructive, challenging and conducive, environment for you
                  to learn, train and grow as individuals and as a group. Our
                  classes are structured based on our 50 – 50 Rule:
                </p>
              </div>
              <div className="mx-auto">
                <img src="/images/50-50-Theory.png" alt="" />
              </div>
              <div className="flex flex-row mt-5 lg:space-x-4">
                <div className="p-3 text-lg border-r-2 border-gray-300 border-dashed w-1/2">
                  <p>
                    50% of the class time is focused on an open and engaging
                    sharing of public speaking skills and techniques, peppered
                    with our professional trainers’ personal experiences and
                    engagement activities.
                  </p>
                </div>
                <div className="p-5 text-lg w-1/2">
                  <p>
                    50% of the class time is dedicated to providing our students
                    with the safe and constructive space to practice, practice
                    and practice their craft! They receive professional,
                    detailed, and dedicated evaluation every session.
                  </p>
                </div>
              </div>
              <p class="p-5 font-text text-lg">
                Our 50 – 50 Rule ensures that all our learners get a valuable
                opportunity to test their learning in a supportive environment,
                receive immediate and relevant feedback, and clarify their
                doubts in their public speaking learning journey. This prepares
                our learners to apply the experience to other speaking
                oppurnities outside!
              </p>

              <div className="w-full bg-slate-100 rounded-xl">
                <div className="pt-10 pb-5">
                  <h2 className="text-xl font-bold text-center font-primary">
                    What Our Adult Learners Have To Say About The Program
                  </h2>
                </div>
                <div className="flex flex-col px-5 md:flex-row">
                  <div className="px-5 pb-10 md:w-1/2">
                    <div className="aspect-video">
                      <iframe
                        class="w-full h-full"
                        src="https://www.youtube.com/embed/ENYBYZDjL2s"
                        title="What Our Students Have To Say • Sebastien Debarge, WCCP Adult Public Speaking Program"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen
                      ></iframe>
                    </div>
                    <div className="py-2">
                      <p className="font-bold text-md font-primary">
                        Sebastien Debarge,
                      </p>
                      <p className="font-bold text-md font-primary">
                        Working professional (Pharmaceutical industry)
                      </p>
                      <p className="font-bold text-md font-primary">
                        Cohort 2019, WCCP Adult Lvl 1
                      </p>
                    </div>
                  </div>
                  <div className="px-5 pb-10 md:w-1/2">
                    <div className="aspect-video">
                      <iframe
                        class="w-full h-full"
                        src="https://www.youtube.com/embed/B-HWMbKVuxw"
                        title="What Our Students Have To Say • Nigel Eu, WCCP Adult Program"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen
                      ></iframe>
                    </div>
                    <div className="py-2">
                      <p className="font-bold text-md font-primary">
                        Nigel Eu,
                      </p>
                      <p className="font-bold text-md font-primary">
                        Fresh Graduate
                      </p>
                      <p className="font-bold text-md font-primary">
                        Cohort 2019, WCCP Adult Lvl 1
                        <br />
                        Grand Finalist, 2019 National Public Speaking
                        Competition (Pri)
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col px-5 md:flex-row">
                  <div className="px-5 pb-10 md:w-1/2">
                    <img src="/images/testimonials-1.png" alt="" />
                  </div>
                  <div className="px-5 pb-10 md:w-1/2">
                    <img src="/images/testimonials-2.png" alt="" />
                  </div>
                </div>
                <div className="flex flex-col px-5 md:flex-row">
                  <div className="px-5 pb-10 md:w-1/2">
                    <img src="/images/testimonials-3.png" alt="" />
                  </div>
                  <div className="px-5 pb-10 md:w-1/2">
                    <img src="/images/testimonials-4.png" alt="" />
                  </div>
                </div>
                <div className="flex flex-col px-5 md:flex-row">
                  <div className="px-5 pb-10 md:w-1/2">
                    <img src="/images/testimonials-5.png" alt="" />
                  </div>
                  <div className="px-5 pb-10 md:w-1/2">
                    <img src="/images/testimonials-6.png" alt="" />
                  </div>
                </div>
              </div>

              <div className="my-10">
                <p className="pt-5 pb-5 text-xl italic font-bold">
                  Our Learning Areas and Scope – World Champion Certification
                  Programme
                </p>
                <p className="text-lg font-text">
                  The World Champion Certification Programme is designed as a
                  structured and guided learning process to tailor to students
                  from different public speaking background or with nuanced
                  learning objectives. The programme covers general
                  communication skills ranging from body language, vocal variety
                  to advanced techniques involving specific speech genres and
                  advanced linguistic skills.
                </p>
              </div>
              <section>
                <div className="py-5">
                  <h2 class="text-center text-4xl font-bold uppercase text-primary italic">
                    COURSE PRICING
                  </h2>
                  <h2 class="text-center text-xl font-bold py-2">
                    World Champion Certification Programme
                  </h2>
                </div>
                <div className="w-full mx-auto md:w-1/2">
                  <div className="py-10 border-2 border-gray-200 rounded-xl">
                    <h2 className="font-bold text-center font-primary">WCCP</h2>
                    <h2 className="py-3 text-4xl font-bold text-center font-primary text-iconColor2">
                      $720
                    </h2>
                    <p className="py-3 text-sm font-bold text-center uppercase font-primary text-primary">
                      Full programme cost (12 lessons)
                    </p>
                    <hr className="w-2/3 h-[3px] mx-auto bg-iconColor1" />
                    <div className="p-14">
                      <ul className="list-disc list-outside">
                        <li className="py-1 text-md font-text">
                          <b>12 weekly</b> lessons (3 months) (at a rate of
                          $240/4 lessons)
                        </li>
                        <li className="py-1 text-md font-text">
                          <b>Duration:</b> 2 Hours per session
                        </li>
                      </ul>
                    </div>
                    <div className="text-sm font-bold text-center uppercase font-primary text-primary">
                      *no registration fee, no material fee, no GST*
                    </div>
                  </div>
                </div>
              </section>

              <section className="my-10">
                <div className="py-5">
                  <h2 class="text-center text-2xl font-bold py-2">
                    Overview of Topics/ Syllabus
                  </h2>
                </div>
                <div className="w-full mx-auto md:w-1/2">
                  <div className="p-10 bg-orange-100 border border-orange-200 rounded-2xl">
                    <div>
                      <p className="pb-1 text-sm font-primary">
                        <b className="text-purple-700">
                          CORE FOCUS I: Speech Delivery
                        </b>{" "}
                        (building the foundation you need to create a strong
                        stage impression)
                      </p>
                      <p className="pb-1 text-sm font-primary">
                        <b className="text-purple-700">Session 1:</b> Body
                        Language Foundation
                      </p>
                      <p className="pb-1 text-sm font-primary">
                        <b className="text-purple-700">Session 2:</b> Vocal
                        Quality, Variety & Clarity
                      </p>
                    </div>

                    <div className="my-3">
                      <p className="pb-1 text-sm font-primary">
                        <b className="text-purple-700">
                          CORE FOCUS II: Speech Content (Planned)
                        </b>{" "}
                        (speech structures for common communication
                        settings/themes)
                      </p>
                      <p className="pb-1 text-sm font-primary">
                        <b className="text-purple-700">Session 3:</b>{" "}
                        Story-Crafting & Audience Engagement
                      </p>
                      <p className="pb-1 text-sm font-primary">
                        <b className="text-purple-700">Session 4:</b>{" "}
                        Informative/Technical Presentations
                      </p>
                      <p className="pb-1 text-sm font-primary">
                        <b className="text-purple-700">Session 5:</b> Persuasive
                        Presentations
                      </p>
                      <p className="pb-1 text-sm font-primary">
                        <b className="text-purple-700">Session 6:</b> Presenting
                        with Visual Aids
                      </p>
                    </div>

                    <div className="my-3">
                      <p className="pb-1 text-sm font-primary">
                        <b className="text-purple-700">
                          CORE FOCUS III: Speech Content (Off-the-Cuff)
                        </b>{" "}
                        (speech techniques for fluid communication
                        settings/themes)
                      </p>
                      <p className="pb-1 text-sm font-primary">
                        <b className="text-purple-700">Session 7:</b> Impromptu
                        Speech
                      </p>
                      <p className="pb-1 text-sm font-primary">
                        <b className="text-purple-700">Session 8:</b> Interview
                        Skills (Pitching)
                      </p>
                      <p className="pb-1 text-sm font-primary">
                        <b className="text-purple-700">Session 9:</b> Group
                        Interview
                      </p>
                    </div>

                    <div className="my-3">
                      <p className="pb-1 text-sm font-primary">
                        <b className="text-purple-700">
                          CORE FOCUS IV: Advanced Techniques
                        </b>{" "}
                        (communication techniques for that confident gloss)
                      </p>
                      <p className="pb-1 text-sm font-primary">
                        <b className="text-purple-700">Session 10:</b> Advanced
                        Linguistics
                      </p>
                      <p className="pb-1 text-sm font-primary">
                        <b className="text-purple-700">Session 11:</b>{" "}
                        Edge-of-the-Seat Blueprint
                      </p>
                      <p className="pb-1 text-sm font-primary">
                        <b className="text-purple-700">Session 12:</b> Tying it
                        all Together
                      </p>
                    </div>
                  </div>
                </div>

                <div class="container my-10">
                  {/* <h2 class="text-primary font-primary font-bold uppercase text-xl md:text-xl text-center py-5">
                    Our Branch Locations
                  </h2> */}
                  <img
                    src="/images/image-of-WCCP-adult-public-speaking-presentation-skills-training-program-schedule-intakes.jpg"
                    className="my-5"
                    alt=""
                  />
                  <p class="font-text text-lg md:text-lg  text-textColor">
                    If you would like to request for a customised course timing
                    for your group of friends or colleagues, please contact us
                    and indicate your request in the contact form below. We will
                    contact you shortly. Please be informed that we have a
                    minimum class size for a customised course timing.
                  </p>
                  <p class="font-text text-lg md:text-lg  text-textColor">
                    If you are a parent and would like to enrich your kids with
                    presentation skills, you may check out our{" "}
                    <NavLink
                      to="/group-classes-students-age-7-18/"
                      className="underline text-slate-700"
                    >
                      public speaking courses for kids here.
                    </NavLink>
                  </p>
                  <div class="flex flex-col md:flex-row space-y-3 md:space-y-0 md:space-x-3 my-5">
                    <img
                      class="md:w-1/3 w-full h-auto rounded-2xl border-r-4 border-b-4 border-[#FFE4D5]"
                      src="/images/group-classes-for-adults-block-1.jpg"
                      alt=""
                    />
                    <img
                      class="md:w-1/3 w-full h-auto rounded-2xl border-r-4 border-b-4 border-[#FFE4D5]"
                      src="/images/group-classes-for-adults-block-2.jpg"
                      alt=""
                    />
                    <img
                      class="md:w-1/3 w-full h-auto rounded-2xl border-r-4 border-b-4 border-[#FFE4D5]"
                      src="/images/group-classes-for-adults-block-3.jpg"
                      alt=""
                    />
                  </div>
                </div>
                <div>
                  <h2 class="text-primary font-primary font-bold uppercase text-xl md:text-xl text-center py-5">
                    Frequently Asked Questions
                  </h2>
                  <div
                    tabIndex={0}
                    className="border collapse collapse-arrow border-base-300 bg-base-100 rounded-box"
                  >
                    <div className="text-xl font-medium collapse-title">
                      How long is each session?
                    </div>
                    <div className="collapse-content">
                      <p>2 Hours</p>
                    </div>
                  </div>
                  <div
                    tabIndex={1}
                    className="border collapse collapse-arrow border-base-300 bg-base-100 rounded-box"
                  >
                    <div className="text-xl font-medium collapse-title">
                      Who teaches the lessons?
                    </div>
                    <div className="collapse-content">
                      <p>
                        Professional trainer from Darren Tay’s teaching faculty.
                      </p>
                    </div>
                  </div>
                  <div
                    tabIndex={2}
                    className="border collapse collapse-arrow border-base-300 bg-base-100 rounded-box"
                  >
                    <div className="text-xl font-medium collapse-title">
                      Will the students be in a different group from the adults?
                    </div>
                    <div className="collapse-content">
                      <p>Yes, they will be grouped according to their age.</p>
                    </div>
                  </div>
                  <div
                    tabIndex={3}
                    className="border collapse collapse-arrow border-base-300 bg-base-100 rounded-box"
                  >
                    <div className="text-xl font-medium collapse-title">
                      How is payment done?
                    </div>
                    <div className="collapse-content">
                      <p>
                        Upon confirmation and registration of participant (via
                        the receipt of the completed registration form),
                        participant will make full payment of the program fee
                        (inclusive of the early-bird 10% discount, if
                        applicable) in order to secure the his/ her seat.
                      </p>
                      <p>
                        Payment can be made through the following modes:
                        <br />
                        1. PayNow
                        <br />
                        2. Fund Transfer
                        <br />
                        3. PayPal (for credit card payments)
                      </p>
                    </div>
                  </div>
                  <div
                    tabIndex={2}
                    className="border collapse collapse-arrow border-base-300 bg-base-100 rounded-box"
                  >
                    <div className="text-xl font-medium collapse-title">
                      What if I miss a lesson?
                    </div>
                    <div className="collapse-content">
                      <p>
                        We do not have any lessons on designated public
                        holidays. However, for any reason that you cannot make
                        it for lessons on regular lesson days, fret not.
                        Although we do not have a replacement class policy, we
                        will assist you in your learning and ensure that you
                        will not be left behind by helping you in 2 areas:
                        <br />
                        1. We offer you all the notes and materials used by the
                        trainer during the lesson which you missed.
                        <br />
                        2. We offer you our online training video(s), featuring
                        our World Champion of Public Speaking, Darren Tay, for
                        the respective lesson(s) missed.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="px-5 pt-5 my-20 mb-10 border border-gray-400 border-dashed rounded-lg">
                  <h3 className="pb-2 text-2xl font-bold text-center font-primary text-primary">
                    To register, please fill up the contact form below and we
                    will process your registration for you.
                  </h3>
                  <form className="flex flex-col p-5 space-y-5" action="">
                    <input
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      type="text"
                      placeholder="ENTER NAME"
                    />
                    <input
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      type="email"
                      placeholder="ENTER EMAIL"
                    />
                    <input
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      type="text"
                      placeholder="ENTER TELEPHONE"
                    />
                    <textarea
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      name=""
                      id=""
                      cols="10"
                      rows="4"
                      placeholder="ENTER MESSAGE"
                    ></textarea>
                    <button
                      className="text-white bg-iconColor2 hover:bg-iconColor1 focus:ring-4 focus:outline-none focus:ring-iconColor1 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
                      type="submit"
                    >
                      SUBMIT
                    </button>
                  </form>
                </div>
              </section>
            </div>
            <div className="hidden lg:inline lg:w-1/3 px-5 pt-5 mb-10 border border-dashed rounded-lg">
              <Sidebar />
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
