import Footer from "../components/Footer";
export default function MediaReleases() {
  return (
    <>
      <section id="breadcrumb" className="bg-[#F5F5FC] py-1">
        <div className="container mx-auto">
          <div className="flex justify-between mx-2">
            <div>Media Releases</div>
            <div>Home / About / Media Releases</div>
          </div>
        </div>
      </section>

      {/* <!-- Mission Vision Section --> */}
      <section id="mission-vision-1">
        <div className="mx-3 mt-10 lg:mx-20 rounded-3xl">
          <div className="container mx-auto">
            <div className="pt-14">
              <div className="flex flex-col space-y-10 lg:space-y-0 lg:space-x-10 lg:flex-row">
                <div className="w-full">
                  <div className="p-5 border border-gray-400 border-dashed rounded-2xl">
                    <div className="">
                      <h2 className="py-4 text-3xl font-bold uppercase text-primary font-primary">
                        Yahoo Finance
                      </h2>
                      <img
                        className="m-auto rounded-lg"
                        src="images/image of 2016 world champion of public speaking darren tay delivering presentation on stage.png"
                        alt=""
                      />
                    </div>
                    <p className="py-4 text-xl font-text text-textColor">
                      (Darren Tay, the 2016 Toastmasters International world
                      champion of public speaking, during his winning speech,
                      “Outsmart; Outlast.”Toastmasters International)
                    </p>
                    <p className="py-4 text-xl font-text text-textColor">
                      Darren Tay, a 27-year-old Singaporean lawyer, became the
                      2016 Toastmasters International world champion of public
                      speaking in August, surviving several rounds of a
                      competition that lasted six months and included more than
                      30,000 competitors from Toastmasters’ public speaking
                      clubs around the world...
                    </p>
                    <div className="py-4">
                      <a
                        href="https://finance.yahoo.com/news/2016-world-champion-public-speaking-203156551.html?guccounter=1"
                        className="px-8 py-2 text-xl font-bold text-white rounded-md bg-btnColor"
                      >
                        READ MORE
                      </a>
                    </div>
                  </div>
                </div>
                <div className="w-full">
                  <div className="p-5 border border-gray-400 border-dashed rounded-2xl">
                    <div className="">
                      <h2 className="py-4 text-3xl font-bold uppercase text-primary font-primary">
                        Business Insider
                      </h2>
                      <img
                        className="m-auto rounded-lg"
                        src="images/image of 2016 world champion of public speaking darren tay delivering presentation.png"
                        alt=""
                      />
                    </div>
                    <p className="py-4 text-xl font-text text-textColor">
                      On Saturday, August 20, 27-year-old Singaporean lawyer
                      Darren Tay became the Toastmasters International world
                      champion of public speaking. He survived several rounds of
                      a competition that lasted six months and included more
                      than 30,000 competitors from Toastmasters public speaking
                      clubs around the world.
                    </p>
                    <p className="py-4 text-xl font-text text-textColor">
                      He and nine other finalists competed at the Toastmasters
                      annual convention, held this year at Marriott Marquis in
                      Washington, D.C. Tay took home first place for his...
                    </p>
                    <div className="py-4">
                      <a
                        href="https://www.businessinsider.com/toastmasters-public-speaking-champion-darren-tay-2016-8"
                        className="px-8 py-2 text-xl font-bold text-white rounded-md bg-btnColor"
                      >
                        READ MORE
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pb-10"></div>
        </div>
      </section>

      <section id="mission-vision-2">
        <div className="mt-20 rounded-3xl bg-[#f5f5f5]">
          <div className="container mx-auto">
            <div className="pt-14">
              <div className="flex flex-col space-y-10 lg:space-y-0 lg:space-x-10 lg:flex-row">
                <div className="w-full">
                  <div className="p-5 mx-3 border border-gray-400 border-dashed rounded-2xl">
                    <div className="">
                      <h2 className="py-4 text-3xl font-bold uppercase text-primary font-primary">
                        Straits Times
                      </h2>
                      <img
                        className="m-auto rounded-lg"
                        src="images/image of 2016 world champion of public speaking darren tay delivering speech on stage.png"
                        alt=""
                      />
                    </div>
                    <p className="py-4 text-xl font-text text-textColor">
                      (Darren Tay, the 2016 Toastmasters International world
                      champion of public speaking, during his winning speech,
                      “Outsmart; Outlast.”Toastmasters International).
                    </p>
                    <p className="py-4 text-xl font-text text-textColor">
                      Darren Tay, a 27-year-old Singaporean lawyer, became the
                      2016 Toastmasters International world champion of public
                      speaking in August, surviving several rounds of a
                      competition that lasted six months and included more than
                      30,000 competitors from Toastmasters’ public speaking
                      clubs around the world...
                    </p>
                    <div className="py-4">
                      <a
                        href="https://www.straitstimes.com/singapore/singaporean-lawyer-wins-international-public-speaking-championship"
                        className="px-8 py-2 text-xl font-bold text-white rounded-md bg-btnColor"
                      >
                        READ MORE
                      </a>
                    </div>
                  </div>
                </div>
                <div className="w-full">
                  <div className="p-5 mx-2 border border-gray-400 border-dashed rounded-2xl">
                    <div className="">
                      <h2 className="py-4 text-3xl font-bold uppercase text-primary font-primary">
                        BBC World News
                      </h2>
                      <img
                        className="m-auto rounded-lg"
                        src="images/image of 2016 world champion of public speaking darren tay presenting to a crowd.png"
                        alt=""
                      />
                    </div>
                    <p className="py-4 text-xl font-text text-textColor">
                      BBC World News Interviews Toastmasters World Champion of
                      Public Speaking Darren Tay
                    </p>

                    <div className="py-4">
                      <a
                        href="https://www.youtube.com/watch?v=zpnQqjkd6Ps"
                        className="px-8 py-2 text-xl font-bold text-white rounded-md bg-btnColor"
                      >
                        READ MORE
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pb-20"></div>
        </div>
      </section>

      <section id="mission-vision-3">
        <div className="mx-3 mt-10 rounded-3xl">
          <div className="container mx-auto">
            <div className="pt-14">
              <div className="flex flex-col space-y-10 lg:space-y-0 lg:space-x-10 lg:flex-row">
                <div className="w-full lg:w-1/2">
                  <div className="p-5 border border-gray-400 border-dashed rounded-2xl">
                    <div className="">
                      <h2 className="py-4 text-3xl font-bold uppercase text-primary font-primary">
                        CNA News Interview
                      </h2>
                      <img
                        className="m-auto rounded-lg"
                        src="images/image of 2016 world champion of public speaking darren tay's speech on stage.png"
                        alt=""
                      />
                    </div>
                    <p className="py-4 text-xl font-text text-textColor">
                      In this video, World Champion of Public Speaking, Darren
                      Tay talks about the speech that he gave in a white
                      underwear.
                    </p>
                    <div className="py-4">
                      <a
                        href="https://www.youtube.com/watch?v=g5NS-YFHEaQ"
                        className="px-8 py-2 text-xl font-bold text-white rounded-md bg-btnColor"
                      >
                        READ MORE
                      </a>
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-1/2">
                  <div className="p-5 border border-gray-400 border-dashed rounded-2xl">
                    <div className="">
                      <h2 className="py-4 text-3xl font-bold uppercase text-primary font-primary">
                        Asiaone
                      </h2>
                      <img
                        className="m-auto rounded-lg"
                        src="images/image of 2016 world champion of public speaking speaking on stage.png"
                        alt=""
                      />
                    </div>
                    <p className="py-4 text-xl font-text text-textColor">
                      According to Toastmasters International District 80, which
                      manages clubs in Singapore, Mr Tay is the second
                      Singaporean representative to have made it to the
                      competition’s final round, after Manoj Vasudevan clinched
                      third place last year.
                    </p>
                    <p className="py-4 text-xl font-text text-textColor">
                      The World Championship of Public Speaking has been held by
                      Toastmasters International since 1938, with a two-year
                      break during World War II...
                    </p>
                    <div className="py-4">
                      <a
                        href="https://www.asiaone.com/singapore/singaporean-wins-world-public-speaking-title"
                        className="px-8 py-2 text-xl font-bold text-white rounded-md bg-btnColor"
                      >
                        READ MORE
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="mission-vision-4">
        <div className="mt-20 rounded-3xl bg-[#f5f5f5]">
          <div className="container mx-auto">
            <div className="pt-14">
              <div className="flex flex-col space-y-10 lg:space-y-0 lg:space-x-10 lg:flex-row">
                <div className="w-full">
                  <div className="p-5 mx-3 border border-gray-400 border-dashed rounded-2xl">
                    <div className="">
                      <h2 className="py-4 text-3xl font-bold uppercase text-primary font-primary">
                        CNN NEWS
                      </h2>
                      <iframe
                        src="https://www.youtube.com/embed/C1fqbmY5iCc"
                        frameborder="0"
                        allowfullscreen
                        className="w-full rounded-lg aspect-video"
                      ></iframe>
                    </div>
                    <p className="py-2 text-xl font-text text-textColor">
                      “ARE YOU READY TO WITNESS THE BEST? THE BEST OF THE BEST?”
                      a caption flashing across a jumbo screen asks the
                      audience. The crowd roars approval. “LET THE CONTEST
                      BEGIN!”
                    </p>
                    <p className="py-2 text-xl font-text text-textColor">
                      It’s the finale of the 2016 World Championship of Public
                      Speaking, but it feels more like fight night in Vegas. Ten
                      finalists warm up backstage like boxers psyching
                      themselves up for the ring. Some bop their heads and
                      shimmy to dance music on their headphones. Others
                      pantomime dramatic hand gestures while rehearsing their
                      lines out loud.
                    </p>
                    <p className="py-2 text-xl font-text text-textColor">
                      They’ve come halfway around the globe to get on a stage
                      and do something most people find frightening. They’re the
                      renegades in an increasingly mute society where people
                      prefer to text and tweet than actually talk to one
                      another. They’ve emerged from a field of 30,000
                      contestants from countries as far away as Australia, South
                      Korea and Zimbabwe. Their seven-minute speeches draw
                      laughter and gasps of surprise. They reduce the audience
                      to silence with heart-tugging confessions.
                    </p>
                    <div className="py-4">
                      <a
                        href="https://edition.cnn.com/2016/10/14/us/toastmasters-champions/index.html"
                        className="px-8 py-2 text-xl font-bold text-white rounded-md bg-btnColor"
                      >
                        READ MORE
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pb-20"></div>
        </div>
      </section>

      <Footer />
    </>
  );
}
