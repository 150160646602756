import React from "react";

const Sidebar = () => {
  return (
    <>
      <div className="py-3">
        <h3 className="pb-2 text-2xl font-bold font-primary text-primary">
          Courses Menu
        </h3>
        <p className="px-5 py-1 my-2 text-lg border border-dashed rounded-lg font-text text-iconColor2 border-iconColor2">
          <a className="" href="#">
            Weekly/Regular Kids Programme
          </a>
        </p>
        <p className="px-5 py-1 my-2 text-lg border border-dashed rounded-lg font-text text-iconColor2 border-iconColor2">
          <a className="" href="#">
            Weekly/Regular Adults Programme
          </a>
        </p>
        <p className="px-5 py-1 my-2 text-lg border border-dashed rounded-lg font-text text-iconColor2 border-iconColor2">
          <a className="" href="#">
            School Holiday Programme (Kids)
          </a>
        </p>
        <p className="px-5 py-1 my-2 text-lg border border-dashed rounded-lg font-text text-iconColor2 border-iconColor2">
          <a className="" href="#">
            Intensive, Master-Class (Adults)
          </a>
        </p>
        <div className="px-5 py-1 my-2 border border-dashed rounded-lg border-iconColor2">
          <p className="text-lg font-bold font-text text-iconColor2">
            Customised/Tailored Training
          </p>
          <p className="ml-3 text-lg font-text text-iconColor2">
            <a className="" href="#">
              Premium 1-to-1
            </a>
          </p>
          <p className="ml-3 text-lg font-text text-iconColor2">
            <a className="" href="#">
              Corporate Programmes
            </a>
          </p>
          <p className="ml-3 text-lg font-text text-iconColor2">
            <a className="" href="#">
              School-Based Programmes
            </a>
          </p>
        </div>
      </div>
      <div className="px-5 pt-5 mb-10 border border-gray-400 border-dashed rounded-lg">
        <h3 className="pb-2 text-2xl font-bold font-primary text-primary">
          Quick Query Form
        </h3>
        <form className="flex flex-col p-5 space-y-5" action="">
          <input
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            type="text"
            placeholder="ENTER NAME"
          />
          <input
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            type="email"
            placeholder="ENTER EMAIL"
          />
          <input
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            type="text"
            placeholder="ENTER TELEPHONE"
          />
          <textarea
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            name=""
            id=""
            cols="10"
            rows="4"
            placeholder="ENTER MESSAGE"
          ></textarea>
          <button
            className="text-white bg-iconColor2 hover:bg-iconColor1 focus:ring-4 focus:outline-none focus:ring-iconColor1 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
            type="submit"
          >
            SUBMIT
          </button>
        </form>
      </div>
    </>
  );
};

export default Sidebar;
