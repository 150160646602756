import Header from "../components/Header";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";

export default function HolidayMasterclasses() {
  return (
    <>
      <section id="breadcrumb" className="bg-[#F5F5FC] py-1">
        <div className="container mx-auto">
          <div className="flex justify-between mx-3">
            <div>S’PEAK PERFORMANCE PROGRAMME (KIDS)</div>
            <div>
              Home / Courses & Programs / Public Speaking for Kids / Holiday
              Camps/Masterclasses
            </div>
          </div>
        </div>
      </section>

      <section id="block-1" className="mx-3">
        <div class="container mx-auto py-20">
          <div class="flex flex-col lg:flex-row pt-5 space-x-10">
            <div class="w-full lg:w-2/3">
              <h1 class="font-primary text-3xl font-bold text-primary uppercase">
                S’Peak Performance Programme (for children ages 9 to 12 and 13
                to 18)
              </h1>
              <h2 class="font-primary text-xl lg:text-2xl font-bold text-primary uppercase py-5">
                2-Day Intensive, Masterclass in Public Speaking
              </h2>
              <div>
                <img
                  src="/images/Kids intensive - featured pic-min.png"
                  alt=""
                />
                <p class="py-5 font-text text-lg">
                  Public speaking is daunting, and finding the right programme
                  for your child is even more of a challenge. A regular, weekly
                  programme may be a big commitment for your child. We have an
                  alternative. Whether you are looking for a quick, deep-dive
                  into public speaking for your budding communicator or want
                  your child to explore public speaking for the first time, our
                  S’Peak Performance Programme is a strong option for you!
                </p>
                <img
                  src="/images/public-speaking-course-for-adults-public-speaking-courses-for-adults-public-speaking-for-adults-2-1.png"
                  alt=""
                />
                <p class="pb-5 pt-5 text-xl font-bold">
                  Introducing our S’Peak Performance Programme (for Children
                  ages 9 to 12 & 13 to 18)
                </p>
                <p class="pb-5 font-text text-lg">
                  The S’Peak Performance Programme is a 2-day intensive
                  masterclass on public speaking, tailored for young
                  communicators looking for a deep-dive into the knowledge,
                  techniques, and tips for public speaking. We have extracted 6
                  core areas from our extensive public speaking syallabus for a
                  focused and tailored programme for your child (e.g., Interview
                  Skills, Story-Telling, Stage Confidence). The S’Peak
                  Performance Programme is helmed personally by our Master
                  Trainer and World Champion of Public Speaking 2016, Darren Tay
                  (assisted by Senior Trainer).
                </p>
              </div>
              <div className="mx-auto">
                <h3 class="font-primary text-3xl font-bold text-primary text-center py-5">
                  The Programme Experience
                </h3>
                <div className="p-5 bg-blue-200 rounded-md ">
                  <h3 class="font-primary text-xl font-bold text-primary text-center">
                    Theory Building
                  </h3>
                  <p className="text-lg text-center ">
                    Learners receive the printed materials and are guided
                    through the techniques by our Trainers, with engagement
                    tools (e.g., quizzes, discussions, challenges)
                  </p>
                </div>
                <div className="my-2">
                  <svg
                    className="mx-auto text-center"
                    width="24"
                    height="58"
                    viewBox="0 0 24 58"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.9393 57.0607C11.5251 57.6464 12.4749 57.6464 13.0607 57.0607L22.6066 47.5147C23.1924 46.9289 23.1924 45.9792 22.6066 45.3934C22.0208 44.8076 21.0711 44.8076 20.4853 45.3934L12 53.8787L3.51472 45.3934C2.92893 44.8076 1.97918 44.8076 1.3934 45.3934C0.807609 45.9792 0.807609 46.9289 1.3934 47.5147L10.9393 57.0607ZM10.5 -6.55671e-08L10.5 56L13.5 56L13.5 6.55671e-08L10.5 -6.55671e-08Z"
                      fill="black"
                    />
                  </svg>
                </div>
                <div className="p-5 bg-blue-200 rounded-md ">
                  <h3 class="font-primary text-xl font-bold text-primary text-center">
                    Breakout Practice
                  </h3>
                  <p className="text-lg text-center ">
                    Our young communicators are given a safe space to explore
                    the techniques and apply the theory in small-scale, engaging
                    break-out sessions
                  </p>
                </div>
                <div className="my-2">
                  <svg
                    className="mx-auto text-center"
                    width="24"
                    height="58"
                    viewBox="0 0 24 58"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.9393 57.0607C11.5251 57.6464 12.4749 57.6464 13.0607 57.0607L22.6066 47.5147C23.1924 46.9289 23.1924 45.9792 22.6066 45.3934C22.0208 44.8076 21.0711 44.8076 20.4853 45.3934L12 53.8787L3.51472 45.3934C2.92893 44.8076 1.97918 44.8076 1.3934 45.3934C0.807609 45.9792 0.807609 46.9289 1.3934 47.5147L10.9393 57.0607ZM10.5 -6.55671e-08L10.5 56L13.5 56L13.5 6.55671e-08L10.5 -6.55671e-08Z"
                      fill="black"
                    />
                  </svg>
                </div>
                <div className="p-5 bg-blue-200 rounded-md ">
                  <h3 class="font-primary text-xl font-bold text-primary text-center">
                    Graduation Showcase
                  </h3>
                  <p className="text-lg text-center ">
                    Learners combine the knowledge and skills gained over the
                    2-days in crafting a final showcase presentation, presented
                    to the full cohort
                  </p>
                </div>
                <div className="my-2">
                  <svg
                    className="mx-auto text-center"
                    width="24"
                    height="58"
                    viewBox="0 0 24 58"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.9393 57.0607C11.5251 57.6464 12.4749 57.6464 13.0607 57.0607L22.6066 47.5147C23.1924 46.9289 23.1924 45.9792 22.6066 45.3934C22.0208 44.8076 21.0711 44.8076 20.4853 45.3934L12 53.8787L3.51472 45.3934C2.92893 44.8076 1.97918 44.8076 1.3934 45.3934C0.807609 45.9792 0.807609 46.9289 1.3934 47.5147L10.9393 57.0607ZM10.5 -6.55671e-08L10.5 56L13.5 56L13.5 6.55671e-08L10.5 -6.55671e-08Z"
                      fill="black"
                    />
                  </svg>
                </div>
                <div className="p-5 bg-orange-200 rounded-md ">
                  <h3 class="font-primary text-xl font-bold text-primary text-center">
                    (Bonus & Free!) Booster Sessions
                  </h3>
                  <p className="text-lg text-center ">
                    Beyond the fee you pay, we add 3 free, bonus Booster
                    Sessions (Online) to continue the communication learning
                    journey! (arranged after the programme concludes)
                  </p>
                </div>
                <div>
                  <p class="py-8 font-text text-lg">
                    The S’Peak Performance Programme syllabus focuses on 6
                    communication domains that apply to our young learners’
                    day-to-day in school, at home, and even in the future. From
                    speech-crafting to interview skills, your child will explore
                    the broad techniques and tips with the guidance of our
                    experienced trainers:
                  </p>
                </div>

                <div className="py-5">
                  <h2 class="text-center text-xl font-bold">
                    The Programme Syllabus
                  </h2>
                  <h2 class="text-center text-4xl font-bold uppercase text-primary">
                    6 communication domains
                  </h2>
                  <div className="flex flex-col lg:flex-row space-y-10 lg:space-y-0 lg:space-x-5 my-14">
                    <div className="w-full lg:w-1/3">
                      <div className="px-3 py-5 border border-dashed border-cyan-200 bg-cyan-100 rounded-2xl">
                        <div className="-mt-8">
                          <p className="px-2 py-2 font-bold text-center text-white rounded-md text-md bg-primary font-primary">
                            Body Language
                          </p>
                        </div>
                        <div className="py-6 font-bold text-md font-text text-textColor">
                          <p className="py-2 text-center">
                            Exploring foundational body langauge and vocal
                            variety elements.
                          </p>
                          <div className="flex flex-row">
                            <ul className="px-2 list-none">
                              <li>Stage Positioning</li>
                              <li>Power Poses</li>
                              <li>Vocal Variety</li>
                              <li>Eye Contact</li>
                            </ul>
                            <ul className="px-2 list-none">
                              <li>Power Pauses</li>
                              <li>Audience Survey & Participation</li>
                              <li>Facial Expressions</li>
                              <li>Articulation</li>
                              <li>Linguistics</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="w-full lg:w-1/3">
                      <div className="px-3 py-5 bg-orange-100 border border-orange-200 border-dashed rounded-2xl">
                        <div className="-mt-8">
                          <p className="px-2 py-2 font-bold text-center text-white bg-orange-600 rounded-md text-md font-primary">
                            In - Class Presentation
                          </p>
                        </div>
                        <div className="py-6 font-bold text-md font-text text-textColor">
                          <p className="py-2 text-center">
                            Enliven in-class presentations to engage the
                            audience
                          </p>
                          <div className="">
                            <ul className="text-center list-none">
                              <li>Story-Crafting</li>
                              <li>Speech Structure</li>
                              <li>Presenting with visual aids</li>
                              <li>Edge of the Seats: 8 Step Blueprint</li>
                              <li>Audience Engagement</li>
                              <li>Engagement</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="w-full lg:w-1/3">
                      <div className="px-3 py-5 border border-dashed border-cyan-200 bg-cyan-100 rounded-2xl">
                        <div className="-mt-8">
                          <p className="px-2 py-2 font-bold text-center text-white rounded-md text-md bg-primary font-primary">
                            Charisma + Dealing With Stage Fright
                          </p>
                        </div>
                        <div className="py-6 font-bold text-md font-text text-textColor">
                          <p className="py-2 text-center">
                            Manage and tackle stage fright/anxiety with charisma
                          </p>
                          <div className="">
                            <ul className="text-center list-none">
                              <li>Advanced Power Poses</li>
                              <li>Visualization for Stage Confidence</li>
                              <li>Rehearsing, Practising Tips</li>
                              <li>Charisma-Building</li>
                              <li>Mindfulness Practice</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col lg:flex-row space-y-10 lg:space-y-0 lg:space-x-5 my-14">
                    <div className="w-full lg:w-1/3">
                      <div className="px-3 py-5 bg-orange-100 border border-orange-200 border-dashed rounded-2xl">
                        <div className="-mt-8">
                          <p className="px-2 py-2 font-bold text-center text-white bg-orange-600 rounded-md text-md font-primary">
                            Interview Mastery
                          </p>
                        </div>
                        <div className="py-6 font-bold text-md font-text text-textColor">
                          <p className="py-2 text-center">
                            Master interview common questions with confidence
                          </p>
                          <div className="">
                            <ul className="text-center list-none">
                              <li>Interview Preparation</li>
                              <li>Top 10 Toughest Interview Questions</li>
                              <li>Acing Group Interviews</li>
                              <li>Answering Techniques</li>
                              <li>Persuasion & Follow-Up</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="w-full lg:w-1/3">
                      <div className="px-3 py-5 border border-dashed bg-cyan-100 border-cyan-200 rounded-2xl">
                        <div className="-mt-8">
                          <p className="px-2 py-2 font-bold text-center text-white rounded-md bg-primary text-md font-primary">
                            Impromptu Speaking
                          </p>
                        </div>
                        <div className="py-6 font-bold text-md font-text text-textColor">
                          <p className="py-2 text-center">
                            Think and respond off-the-cuff in managing impromptu
                            settings
                          </p>
                          <div className="">
                            <ul className="text-center list-none">
                              <li>Tell a Captivating Story and Make a Point</li>
                              <li>Use Interrogative Pronouns</li>
                              <li>Past, Present, Future model</li>
                              <li>Pendulum Method</li>
                              <li>P.E.E.L Approach</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="w-full lg:w-1/3">
                      <div className="px-3 py-5 bg-orange-100 border border-orange-200 border-dashed rounded-2xl">
                        <div className="-mt-8">
                          <p className="px-2 py-2 font-bold text-center text-white bg-orange-600 rounded-md text-md font-primary">
                            Oral Exam
                          </p>
                        </div>
                        <div className="py-6 font-bold text-md font-text text-textColor">
                          <p className="py-2 text-center">
                            Apply public speaking techniques to Oral Examination
                            settings
                          </p>
                          <div className="">
                            <ul className="text-center list-none">
                              <li>Pronunciation & Enunciation</li>
                              <li>Diction, Tonality, Context</li>
                              <li>Fluency, Purpose, Expressiveness</li>
                              <li>Development of Rich Points</li>
                              <li>Mastering Question Types</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <section id="review">
                  <div className="w-full py-5 my-5 bg-slate-100 rounded-xl">
                    <div className="pt-10 pb-5">
                      <h2 className="text-xl font-bold text-center font-primary">
                        What Our Students Have To Say About Their Transformative
                        Experience:
                      </h2>
                    </div>
                    <div className="flex flex-col px-5 md:flex-row">
                      <div className="px-5 pb-10 md:w-1/2">
                        <div className="aspect-video">
                          <iframe
                            class="w-full h-full"
                            src="https://www.youtube.com/embed/cbRctQf0cv8"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowfullscreen
                          ></iframe>
                        </div>
                      </div>
                      <div className="px-5 pb-10 md:w-1/2">
                        <div className="aspect-video">
                          <iframe
                            class="w-full h-full"
                            src="https://www.youtube.com/embed/J1BZp26CXV4"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowfullscreen
                          ></iframe>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section id="block-3">
                  <div>
                    <p class="text-2xl font-bold py-2 italic">
                      The Programme Details - How Do I Enrol My Child?
                    </p>
                    <p class="pb-5 font-text text-lg">
                      The S’Peak Performance Programme is only conducted at our
                      SAFRA Tampines Branch (1A Tampines Street 92 #01-13B
                      (SAFRA Tampines) Singapore-528882), with the following
                      schedule (1 free lunch provided per day, per pax):
                    </p>
                  </div>
                  <div className="flex flex-row space-x-5">
                    <div className="w-1/2 py-5 rounded-lg bg-cyan-50">
                      <h2 className="text-xl font-bold text-center font-primary">
                        9 to 12 years old
                      </h2>
                      <h3 className="text-lg text-center font-primary">
                        Primary School Group
                      </h3>
                      <div className="py-5 text-center">
                        <p>8 & 9 June 2023 (Thur & Fri)</p>
                        <p>9.30 am to 6.00 pm on both days</p>
                        <p className="font-bold text-red-600">
                          Open for registration!
                        </p>
                      </div>
                    </div>
                    <div className="w-1/2 py-5 bg-orange-100 rounded-lg">
                      <h2 className="text-xl font-bold text-center font-primary">
                        13 to 18 years old
                      </h2>
                      <h3 className="text-lg text-center font-primary">
                        Sec School/Teen Group
                      </h3>
                      <div className="py-5 text-center">
                        <p>15 & 16 June 2023 (Thur & Fri)</p>
                        <p>9.30 am to 6.00 pm on both days</p>
                        <p className="font-bold text-red-600">
                          Open for registration!
                        </p>
                      </div>
                    </div>
                  </div>
                  <p class="py-5 font-text text-lg text-center font-bold">
                    (doors open for attendance from 9 to 9.30 am for both
                    sessions, with class starting strictly at 9.30 am)
                  </p>
                  <div className="w-full p-5 my-10 bg-gray-100 rounded-lg">
                    <h2 className="text-xl font-bold text-center font-primary">
                      Price Details
                    </h2>
                    <h3 className="py-3 text-2xl text-center font-text">
                      We are offering a limited seats-only rate of – S$549 nett
                    </h3>
                    <h3 className="text-lg text-center font-text">
                      We are offering a limited seats-only rate of – S$549 nett
                      (all materials, evaluation, certificates included)
                    </h3>
                  </div>
                  <div className="my-10 text-center">
                    <a
                      className="px-4 py-4 text-md lg:text-xl font-bold text-white font-primary bg-btnColor rounded-xl"
                      href="https://docs.google.com/document/d/1vRGposm0rlhUid5RJZVUv-2_trEvyQtkdFKyVe3apsA/edit?usp=sharing"
                    >
                      Click here to book your slot! (instructions on next page)
                    </a>
                  </div>
                </section>
                <section>
                  <h2 className="my-10 text-2xl font-bold text-center">
                    Other Key Programme Details
                  </h2>

                  <div className="flex flex-row items-center border-t border-gray-200">
                    <div className="w-1/3 p-5 font-bold text-center bg-white">
                      Where is the class conducted?
                    </div>
                    <div className="w-2/3 p-5 bg-gray-100">
                      <b>SAFRA TAMPINES BRANCH</b>
                      <br />
                      Public Speaking Academy @ Tampines <br />
                      1A Tampines Street 92 #01-13B <br />
                      SAFRA Tampines <br />
                      Singapore-528882.
                    </div>
                  </div>
                  <div className="flex flex-row items-center border-t border-gray-200">
                    <div className="w-1/3 p-5 font-bold text-center bg-white">
                      What is the class size?
                      <br />
                      Can I attend a trial?
                    </div>
                    <div className="w-2/3 p-5 bg-gray-100">
                      Maximum class size of 15 students – as this is an
                      intensive programme with limited capacity, there is no
                      trial class. Likewise, only enrolled students will be
                      allowed to sit in for the programme to ensure a
                      constructive and safe learning environment.
                    </div>
                  </div>
                  <div className="flex flex-row items-center border-t border-gray-200">
                    <div className="w-1/3 p-5 font-bold text-center bg-white">
                      Who are the Trainers?
                    </div>
                    <div className="w-2/3 p-5 bg-gray-100">
                      The programme will be helmed by our Principal/ World
                      Champion of Public Speaking Mr Darren Tay, and our senior
                      trainer (with each Trainer taking one day each)
                    </div>
                  </div>
                  <div className="flex flex-row items-center border-t border-gray-200">
                    <div className="w-1/3 p-5 font-bold text-center bg-white">
                      What if My child Misses a Class or needs to leave early?
                    </div>
                    <div className="w-2/3 p-5 bg-gray-100">
                      There is no replacement for the missed session or a
                      refund/pro-rate for the session/portions missed. We
                      recommend parents/students to confirm their availability
                      for the full programme before signing up (you may instead
                      consider our regular public speaking programme).
                    </div>
                  </div>
                  <div className="flex flex-row items-center border-t border-gray-200">
                    <div className="w-1/3 p-5 font-bold text-center bg-white">
                      How are your classes conducted with the present COVID-19
                      situation?
                    </div>
                    <div className="w-2/3 p-5 bg-gray-100">
                      Our programmes are conducted with proper clearance from
                      the relevant Government authorities (Ministry of Education
                      and EnterpriseSG). We also abide by our Safety Management
                      Plan which measures such as safe distancing, mandatory
                      mask-wearing, temperature-taking etc. (feel free to
                      request for a copy of our Safety Management Plan).
                      <br />
                      <br />
                      In the event of any COVID-19 measures, legislation, or any
                      directive from the Government disrupting the conduct of
                      the programme, there will be no refund of the fees paid.
                      Instead, the programme will be postponed to another
                      date/time (as per our Terms & Conditions in our
                      Registration Form).
                    </div>
                  </div>
                  <div className="flex flex-row items-center border-gray-200 border-y">
                    <div className="w-1/3 p-5 font-bold text-center bg-white">
                      What if I miss a Booster Session? Can I get a discount?
                    </div>
                    <div className="w-2/3 p-5 bg-gray-100">
                      The Booster sessions are free, additional value we are
                      providing beyond the price of the programme. These
                      sessions are optional and free for the graduates of our
                      programme. Hence, there will be no reduction in price even
                      if students cannot make it for the Booster session(s).
                    </div>
                  </div>
                </section>
                <section>
                  <h2 className="my-10 text-2xl font-bold text-center">
                    Find out more about our Track Record since 2009!
                  </h2>
                  <img
                    className="my-3"
                    src="/images/image showing satisfied corporate clients of s'peak performance public speaking skills training program-min.png"
                    alt=""
                  />
                  <img
                    className="my-3 text-center"
                    src="/images/public-speaking-course-for-adults-public-speaking-courses-for-adults-public-speaking-for-adults-3-3.jpg"
                    alt=""
                  />
                </section>
                <section>
                  <div className="my-10">
                    <p class="text-lg font-bold pb-5">
                      Enrich Your Child/ Children’s Skillset This School
                      Holiday! 😊
                    </p>
                    <p class="font-text text-lg">
                      Register by clicking the button below! (you will be
                      brought to a page with details on how to register and make
                      payment).
                    </p>
                    <div className="my-10 text-center">
                      <a
                        className="px-4 py-4 text-md lg:text-xl font-bold text-white font-primary bg-btnColor rounded-xl"
                        href="#"
                      >
                        Click here to book your slot! (instructions on next
                        page)
                      </a>
                    </div>
                    <p class="text-lg font-bold pb-5">
                      If you have any queries before signing up, please send us
                      an email (to: contact@publicspeakingacademy.com.sg) with
                      “SPP 2023 Kids” in the email header and we will get back
                      to you on your query!
                    </p>
                    <p class="font-text text-lg">
                      If you are keen to develop your child into a confident,
                      competent, and creative speaker/communicator/leader these
                      holidays, join us for the S’Peak Performance Programme
                      (Kids)! We look forward to sharing tried and tested
                      know-hows, techniques, and experiences with your
                      child/children to help them enhance their presentation
                      abilities and boost their speaking confidence!
                    </p>
                  </div>
                </section>
              </div>
            </div>
            <div className="hidden md:inline md:w-1/3 px-5 pt-5 mb-10 border border-dashed rounded-lg">
              <Sidebar />
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
