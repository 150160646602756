import Header from "../components/Header";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";

export default function RegularWeeklyClasses() {
  return (
    <>
      <section id="breadcrumb" className="bg-[#F5F5FC] py-1">
        <div className="container mx-auto">
          <div className="flex justify-between mx-3">
            <div>Regular, Weekly Classes</div>
            <div>Home / Public Speaking for Kids / Regular, Weekly Classes</div>
          </div>
        </div>
      </section>

      <section id="block-1" className="mx-3">
        <div class="container mx-auto py-20">
          <div class="flex flex-col lg:flex-row pt-5 space-y-10 lg:space-y-0 lg:space-x-5">
            <div class="w-full lg:w-2/3">
              <h1 class="font-primary text-3xl font-bold text-primary uppercase">
                Speech Excellence Programme (for children ages 7 to 18)
              </h1>
              <h2 class="font-primary text-xl lg:text-2xl font-bold text-primary uppercase py-5">
                Regular Group Public Speaking Training for Kids (Weekly)
              </h2>
              <div>
                <img src="/images/PSA-Block.jpg" alt="" />
                <p class="py-5 font-text text-lg">
                  Would you believe it if we told you that our very own Darren
                  Tay started as a shy, introvert student at the young age of
                  14, before becoming the World Champion of Public Speaking? We
                  understand that journey, and we want to help your child
                  develop the same communication confidence. To us, public
                  speaking is more than just a stage performance - in the modern
                  class, school, and workplace, effective communication skills
                  remain one of the highly-sought skills. We want to help your
                  child get a head-start in standing out through charismatic,
                  confident, and competent communication skills!
                </p>
                <p class="pb-5 pt-5 text-xl font-bold">
                  Introducing our Speech Excellence Programme (for Children ages
                  7 to 18)
                </p>
                <p class="pb-5 font-text text-lg">
                  The Speech Excellence Programme is a carefully crafted public
                  speaking course for children in Singapore, designed to boost
                  their confidence, introduce them to effective communication
                  skills and transform them into powerful speakers both in
                  school and their future workplace. The programme is divided
                  into the age groups of 7 to 8 (Lower Pri), 9 to 12 (Upper
                  Pri), and 13 to 18 years old (Sec & Teens). Designed by our
                  World Champion public speaking faculty, the programme offers a
                  1-year syllabus, with monthly themes covering consistent,
                  current, and relevant public speaking techniques and
                  communication skills. The Programme is also registered with
                  the Ministry of Education, and aims to impart public speaking
                  skills for kids by, among others:
                </p>
                <p class="pb-5 text-lg">
                  <strong>Confidence Building & Conquering the Stage</strong> –
                  introducing children to the necessary techniques and skillsets
                  to build up their speaking confidence and conquer stage
                  anxiety (perfect for both beginners and confident speakers!)
                </p>
                <p class="pb-5 text-lg">
                  <strong>
                    Transformative Communication Skills for Children
                  </strong>{" "}
                  – communication skills for your child to transform the way
                  they view public speaking and to master as they grow as young,
                  competent public speakers
                </p>
                <p class="pb-5 text-lg">
                  <strong>Prospects & Horizon Expansion for Your Child</strong>{" "}
                  – expanding the prospects of your child by starting young with
                  confidence-building, and growth as a powerful and compelling
                  speaker – in school, at home or even in their future workplace
                </p>
                <p class="pb-5 text-lg">
                  <strong>
                    Stepping Out of the Comfort Zone & Stepping into the
                    Spotlight
                  </strong>{" "}
                  – consistent practice opportunities for your child to master
                  public speaking skills and challenge themselves through public
                  speaking or debating competitions
                </p>
                <p class="pb-5 pt-5 text-xl font-bold">
                  The Public Speaking Learning Experience for Your Child
                </p>
                <p class="pb-5 text-lg">
                  Our commitment to the learning goal is inspired by our very
                  own Darren Tay’s public speaking journey. The Speech
                  Excellence Programme builds on the necessary effective
                  communication skills to nurture your child into an all-rounded
                  and accomplished communicator. The syllabus is designed
                  separately for the age groups of 7 to 8 years old and 9 to 18
                  years old to focus on relevant communication skill-sets for
                  each group. Our complete syllabus covers two pillars, namely:
                </p>
                <p class="pb-5 text-lg">
                  <strong>
                    Core focus on foundational skills (such as body language,
                    overcoming anxiety)
                  </strong>{" "}
                  – our professional trainers will observe and guide your
                  child’s growth in these foundational areas to ensure mastery
                  of body language and stage confidence, throughout the course
                </p>
                <p class="pb-5 text-lg">
                  <strong>
                    Specific theme focus on communication skills (such as
                    persuasive speech, impromptu speaking, oral examination
                    mastery)
                  </strong>{" "}
                  – the syllabus is segmented into various themes each month for
                  a more targeted and in-depth exploration of unique
                  communication skills, matching the education-cycle of our
                  young communicators
                </p>
                <p class="pb-5 pt-5 text-3xl lg:text-4xl font-bold">
                  Syllabus for 7 to 8 Years Old
                </p>
                <p class="pb-5 text-lg">
                  Our syllabus for the 7 to 8 years old group mirrors the
                  education path of our learners in their formative years,
                  balancing the formal education focus and the power skill of
                  public speaking. Each month, learners are guided on various
                  themes that builds communication confidence alongside their
                  school syllabus (e.g., Show & Tell, Storytelling) while
                  bringing our public speaking emphasis in expanding our young
                  communicators’ confidence.
                </p>
              </div>
            </div>
            <div class="hidden lg:inline lg:w-1/3 border border-dashed rounded-lg pt-5 px-5 mb-10">
              <Sidebar />
            </div>
          </div>
        </div>
      </section>
      <section id="block-2" className="mx-3">
        <div className="container mx-auto">
          <div className="flex flex-row items-center">
            <div className="w-2/3">
              <h2 className="tracking-wide text-center uppercase text-2xl lg:text-7xl font-primary">
                Theme Focus
              </h2>
            </div>
            <div className="w-1/3">
              <h2 className="py-2 text-2xl lg:text-5xl font-bold text-center uppercase font-text text-iconColor1">
                Our Complete
              </h2>
              <h2 className="py-2 text-2xl lg:text-5xl font-bold text-center uppercase font-text">
                1-Year Syllabus
              </h2>
            </div>
          </div>
        </div>
      </section>
      <section id="block-3">
        <div class="container mx-auto">
          <div class="flex flex-row items-center pt-5 border border-dashed rounded-lg p-5">
            <div class="w-full lg:w-1/2">
              <div>
                <div class="grid grid-cols-3 gap-1 lg:gap-5">
                  <div className="lg:relative p-2 lg:p-3 rounded-md bg-sky-700">
                    <h2 className="text-lg lg:text-xl xl:text-2xl font-bold text-white uppercase font-primary">
                      JANUARY
                    </h2>
                    <h3 className="text-sm font-bold text-white uppercase font-text">
                      Show and tell focus
                    </h3>
                    <ul className="p-4 list-disc list-outside w-36 lg:w-full">
                      <li className="font-semibold text-white font-text">
                        Pronunciation & Enunciation
                      </li>
                      <li className="font-semibold text-white font-text">
                        Show and Tell (Object At Home)
                      </li>
                    </ul>
                    <img
                      className="hidden lg:inline absolute bottom-0 lg:w-16 lg:left-20 xl:w-20 xl:left-36 2xl:left-44"
                      src="/images/1.png"
                      alt=""
                    />
                  </div>
                  <div className="lg:relative p-2 lg:p-3 rounded-md bg-sky-700">
                    <h2 className="text-lg lg:text-xl xl:text-2xl font-bold text-white uppercase font-primary">
                      February
                    </h2>
                    <h3 className="text-sm font-bold text-white uppercase font-text">
                      Pick & Tell Focus
                    </h3>
                    <ul className="p-4 list-disc list-outside w-36 lg:w-full">
                      <li className="font-semibold text-white font-text">
                        Pronunciation & Enunciation
                      </li>
                      <li className="font-semibold text-white font-text">
                        Pick and Tell (Object in School)
                      </li>
                    </ul>
                  </div>
                  <div className="lg:relative p-2 lg:p-3 rounded-md bg-sky-700">
                    <h2 className="text-lg lg:text-xl xl:text-2xl font-bold text-white uppercase font-primary">
                      March
                    </h2>
                    <h3 className="text-sm font-bold text-white uppercase font-text">
                      Discuss and tell focus
                    </h3>
                    <ul className="p-4 list-disc list-outside w-36 lg:w-full">
                      <li className="font-semibold text-white font-text">
                        Pronunciation & Enunciation
                      </li>
                      <li className="font-semibold text-white font-text">
                        Discuss and Tell (Group Discussion)
                      </li>
                    </ul>
                    <img
                      className="hidden lg:inline absolute bottom-0 lg:w-20 lg:left-28 xl:w-20 xl:left-40 2xl:left-48"
                      src="/images/3.png"
                      alt=""
                    />
                  </div>
                  <div className="lg:relative p-2 lg:p-3 rounded-md bg-orange-400">
                    <h2 className="text-lg lg:text-xl xl:text-2xl font-bold text-white uppercase font-primary">
                      April
                    </h2>
                    <h3 className="text-sm font-bold text-white uppercase font-text">
                      Fluency & Conversation
                    </h3>
                    <ul className="p-4 list-disc list-outside w-36 lg:w-full">
                      <li className="font-semibold text-white font-text">
                        Stimulus-based Conversations
                      </li>
                    </ul>
                  </div>
                  <div className="lg:relative p-2 lg:p-3 rounded-md bg-orange-400">
                    <h2 className="text-lg lg:text-xl xl:text-2xl font-bold text-white uppercase font-primary">
                      May
                    </h2>
                    <h3 className="text-sm font-bold text-white uppercase font-text">
                      Storytelling Focus
                    </h3>
                    <ul className="p-4 list-disc list-outside w-36 lg:w-full">
                      <li className="font-semibold text-white font-text">
                        Storytelling (recount) with body language
                      </li>
                    </ul>
                    <img
                      className="hidden lg:inline absolute bottom-0 w-32 left-28 xl:left-32 2xl:left-44"
                      src="/images/2.png"
                      alt=""
                    />
                  </div>
                  <div className="lg:relative p-2 lg:p-3 rounded-md bg-orange-400">
                    <h2 className="text-lg lg:text-xl xl:text-2xl font-bold text-white uppercase font-primary">
                      June
                    </h2>
                    <h3 className="text-sm font-bold text-white uppercase font-text">
                      Storytelling Focus
                    </h3>
                    <ul className="p-4 list-disc list-outside w-36 lg:w-full">
                      <li className="font-semibold text-white font-text">
                        Storytelling (pair work) with body language
                      </li>
                    </ul>
                  </div>

                  <div className="lg:relative p-2 lg:p-3 rounded-md bg-sky-700">
                    <h2 className="text-lg lg:text-xl xl:text-2xl font-bold text-white uppercase font-primary">
                      July
                    </h2>
                    <h3 className="text-sm font-bold text-white uppercase font-text">
                      Picture Analysis Focus
                    </h3>
                    <ul className="p-4 list-disc list-outside w-36 lg:w-full">
                      <li className="font-semibold text-white font-text">
                        Picture description with body language
                      </li>
                    </ul>
                    <img
                      className="hidden lg:inline absolute bottom-0 lg:w-16 lg:left-28 xl:left-40 2xl:left-48"
                      src="/images/4.png"
                      alt=""
                    />
                  </div>
                  <div className="lg:relative p-2 lg:p-3 rounded-md bg-sky-700">
                    <h2 className="text-lg lg:text-xl xl:text-2xl font-bold text-white uppercase font-primary">
                      August
                    </h2>
                    <h3 className="text-sm font-bold text-white uppercase font-text">
                      Speech Crafting (Instruction) Focus
                    </h3>
                    <ul className="p-4 list-disc list-outside w-36 lg:w-full">
                      <li className="font-semibold text-white font-text">
                        Speech-craft (to instruct) with body language
                      </li>
                    </ul>
                  </div>
                  <div className="lg:relative p-2 lg:p-3 rounded-md bg-sky-700">
                    <h2 className="text-lg lg:text-xl xl:text-2xl font-bold text-white uppercase font-primary">
                      September
                    </h2>
                    <h3 className="text-sm font-bold text-white uppercase font-text">
                      Speech Crafting (Informative) Focus
                    </h3>
                    <ul className="p-4 list-disc list-outside w-36 lg:w-full">
                      <li className="font-semibold text-white font-text">
                        Speech-craft (to inform) with body language
                      </li>
                    </ul>
                    <img
                      className="hidden lg:inline absolute bottom-0 w-20 lg:left-32 xl:left-40 2xl:left-48"
                      src="/images/5.png"
                      alt=""
                    />
                  </div>
                  <div className="lg:relative p-2 lg:p-3 rounded-md bg-orange-400">
                    <h2 className="text-lg lg:text-xl xl:text-2xl font-bold text-white uppercase font-primary">
                      October
                    </h2>
                    <h3 className="text-sm font-bold text-white uppercase font-text">
                      Speech Crafting (Influence) Focus
                    </h3>
                    <ul className="p-4 list-disc list-outside w-36 lg:w-full">
                      <li className="font-semibold text-white font-text">
                        Speech-craft (to influence) with body language
                      </li>
                    </ul>
                  </div>
                  <div className="lg:relative p-2 lg:p-3 rounded-md bg-orange-400">
                    <h2 className="text-lg lg:text-xl xl:text-2xl font-bold text-white uppercase font-primary">
                      November
                    </h2>
                    <h3 className="text-sm font-bold text-white uppercase font-text">
                      Emceeing & Engagement Focus
                    </h3>
                    <ul className="p-4 list-disc list-outside w-36 lg:w-full">
                      <li className="font-semibold text-white font-text">
                        Being a Master of Ceremony (emceeing) with body language
                      </li>
                    </ul>
                    <img
                      className="hidden lg:inline absolute bottom-0 w-20 lg:left-28 xl:left-36 2xl:left-44"
                      src="/images/6.png"
                      alt=""
                    />
                  </div>
                  <div className="lg:relative p-2 lg:p-3 rounded-md bg-orange-400">
                    <h2 className="text-lg lg:text-xl xl:text-2xl font-bold text-white uppercase font-primary">
                      December
                    </h2>
                    <h3 className="text-sm font-bold text-white uppercase font-text">
                      Review Communication Focus
                    </h3>
                    <ul className="p-4 list-disc list-outside w-36 lg:w-full">
                      <li className="font-semibold text-white font-text">
                        Delivering Reviews (Book, Food, Place) with Show & Tell
                        elements
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="hidden lg:inline lg:w-1/4">
              <div className="py-20">
                <div className="lg:rotate-90">
                  <h3 className="text-xl font-bold text-center font-text">
                    Foundational elements for all 12 months.
                  </h3>
                  <h2 className="lg:text-3xl xl:text-5xl font-bold text-center font-primary">
                    CORE FOCUS
                  </h2>
                </div>
              </div>
            </div>
            <div className="hidden lg:inline lg:w-1/4">
              <div className="py-20">
                <div className="py-5">
                  <h3 className="text-3xl font-bold">Body Language</h3>
                  <svg
                    width="300"
                    height="23"
                    viewBox="0 0 387 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M385.584 12.2441C386.169 11.6567 386.166 10.7069 385.579 10.1227L376.007 0.602987C375.419 0.0188074 374.469 0.0214094 373.885 0.608799C373.301 1.19619 373.304 2.14593 373.891 2.73011L382.4 11.1921L373.938 19.7006C373.353 20.288 373.356 21.2377 373.943 21.8219C374.531 22.4061 375.48 22.4035 376.065 21.8161L385.584 12.2441ZM0.908895 13.7373L384.525 12.6863L384.517 9.68631L0.900676 10.7373L0.908895 13.7373Z"
                      fill="#F79E1B"
                    />
                  </svg>
                </div>
                <div className="py-5">
                  <h3 className="text-3xl font-bold">
                    Reading Aloud with Vocal Variety
                  </h3>
                  <svg
                    width="300"
                    height="23"
                    viewBox="0 0 387 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M385.584 12.2441C386.169 11.6567 386.166 10.7069 385.579 10.1227L376.007 0.602987C375.419 0.0188074 374.469 0.0214094 373.885 0.608799C373.301 1.19619 373.304 2.14593 373.891 2.73011L382.4 11.1921L373.938 19.7006C373.353 20.288 373.356 21.2377 373.943 21.8219C374.531 22.4061 375.48 22.4035 376.065 21.8161L385.584 12.2441ZM0.908895 13.7373L384.525 12.6863L384.517 9.68631L0.900676 10.7373L0.908895 13.7373Z"
                      fill="#F79E1B"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="block-3" className="mx-3">
        <div class="container mx-auto">
          <div class="flex flex-col lg:flex-row py-14">
            <div class="w-full lg:w-2/3">
              <div>
                <p class="text-3xl lg:text-4xl font-bold py-3">
                  Syllabus for 9 to 18 Years Old
                </p>
                <p class="pb-5 font-text text-lg">
                  Our syllabus for the 9 to 18 years old group continues the
                  communication growth journey as our young communicators
                  explore more formal speech settings such as in-class
                  presentations, project presentations, speeches, or
                  competitions. The focus shifts to building foundational public
                  speaking techniques with a focus on elements such as body
                  language, vocal variety, and even content structures. Our
                  young communicators will continue to build their confidence
                  beyond their two feet!
                </p>
              </div>
            </div>
            <div class="w-full lg:w-1/3"></div>
          </div>
        </div>
      </section>

      <section id="block-4" className="mx-3">
        <div className="container mx-auto">
          <div className="flex flex-row items-center">
            <div className="w-2/3">
              <h2 className="tracking-wide text-center uppercase text-2xl lg:text-7xl font-primary">
                Theme Focus
              </h2>
            </div>
            <div className="w-1/3">
              <h2 className="py-2 text-2xl lg:text-5xl font-bold text-center uppercase font-text text-iconColor1">
                Our Complete
              </h2>
              <h2 className="py-2 text-2xl lg:text-5xl font-bold text-center uppercase font-text">
                1-Year Syllabus
              </h2>
            </div>
          </div>
        </div>
      </section>
      <section id="block-5">
        <div class="container mx-auto">
          <div class="flex flex-row items-center pt-5 border border-dashed rounded-lg p-5">
            <div class="w-full lg:w-1/2">
              <div>
                <div class="grid grid-cols-3 gap-1 lg:gap-5">
                  <div className="lg:relative p-2 lg:p-3 rounded-md bg-sky-700">
                    <h2 className="text-lg lg:text-xl xl:text-2xl font-bold text-white uppercase font-primary">
                      JANUARY
                    </h2>
                    <ul className="p-4 list-disc list-outside w-36 lg:w-full">
                      <li className="font-semibold text-white font-text">
                        Pitching (Ice Breaker)
                      </li>
                      <li className="font-semibold text-white font-text">
                        Interpersonal Communication
                      </li>
                    </ul>
                    <img
                      className="hidden lg:inline absolute bottom-0 w-16 lg:left-32 xl:left-36 2xl:left-44"
                      src="/images/7.png"
                      alt=""
                    />
                  </div>
                  <div className="lg:relative p-2 lg:p-3 rounded-md bg-sky-700">
                    <h2 className="text-lg lg:text-xl xl:text-2xl font-bold text-white uppercase font-primary">
                      February
                    </h2>
                    <ul className="p-4 list-disc list-outside w-36 lg:w-full">
                      <li className="font-semibold text-white font-text">
                        Speech Structure
                      </li>
                      <li className="font-semibold text-white font-text">
                        Leadership Communication
                      </li>
                    </ul>
                  </div>
                  <div className="lg:relative p-2 lg:p-3 rounded-md bg-sky-700">
                    <h2 className="text-lg lg:text-xl xl:text-2xl font-bold text-white uppercase font-primary">
                      March
                    </h2>
                    <ul className="p-4 list-disc list-outside w-36 lg:w-full">
                      <li className="font-semibold text-white font-text">
                        Demonstration Speech
                      </li>
                      <li className="font-semibold text-white font-text">
                        Motivational Speech
                      </li>
                    </ul>
                    <img
                      className="hidden lg:inline absolute bottom-0 w-24 lg:left-28 xl:left-32 2xl:left-40"
                      src="/images/8.png"
                      alt=""
                    />
                  </div>
                  <div className="lg:relative p-2 lg:p-3 rounded-md bg-orange-400">
                    <h2 className="text-lg lg:text-xl xl:text-2xl font-bold text-white uppercase font-primary">
                      April
                    </h2>
                    <ul className="p-4 list-disc list-outside w-36 lg:w-full">
                      <li className="font-semibold text-white font-text">
                        Persuasive Speech
                      </li>
                    </ul>
                  </div>
                  <div className="lg:relative p-2 lg:p-3 rounded-md bg-orange-400">
                    <h2 className="text-lg lg:text-xl xl:text-2xl font-bold text-white uppercase font-primary">
                      May
                    </h2>
                    <ul className="p-4 list-disc list-outside w-36 lg:w-full">
                      <li className="font-semibold text-white font-text">
                        Impromptu Speech
                      </li>
                    </ul>
                    <img
                      className="hidden lg:inline absolute bottom-0 w-20 lg:left-24 xl:left-36 2xl:left-44"
                      src="/images/9.png"
                      alt=""
                    />
                  </div>
                  <div className="lg:relative p-2 lg:p-3 rounded-md bg-orange-400">
                    <h2 className="text-lg lg:text-xl xl:text-2xl font-bold text-white uppercase font-primary">
                      June
                    </h2>
                    <ul className="p-4 list-disc list-outside w-36 lg:w-full">
                      <li className="font-semibold text-white font-text">
                        Oral Exam Mastery
                      </li>
                      <li className="font-semibold text-white font-text">
                        Conversation Mastery
                      </li>
                      <li className="font-semibold text-white font-text">
                        Vocal Clarity
                      </li>
                    </ul>
                  </div>

                  <div className="lg:relative p-2 lg:p-3 rounded-md bg-sky-700">
                    <h2 className="text-lg lg:text-xl xl:text-2xl font-bold text-white uppercase font-primary">
                      July
                    </h2>
                    <ul className="p-4 list-disc list-outside w-36 lg:w-full">
                      <li className="font-semibold text-white font-text">
                        Interview Skills
                      </li>
                      <li className="font-semibold text-white font-text">
                        Group Interview
                      </li>
                    </ul>
                    <img
                      className="hidden lg:inline absolute bottom-0 w-20 lg:left-20 xl:left-32 2xl:left-36"
                      src="/images/10.png"
                      alt=""
                    />
                  </div>
                  <div className="lg:relative p-2 lg:p-3 rounded-md bg-sky-700">
                    <h2 className="text-lg lg:text-xl xl:text-2xl font-bold text-white uppercase font-primary">
                      August
                    </h2>
                    <ul className="p-4 list-disc list-outside w-36 lg:w-full">
                      <li className="font-semibold text-white font-text">
                        Story-telling
                      </li>
                      <li className="font-semibold text-white font-text">
                        In-Class Presentations
                      </li>
                    </ul>
                  </div>
                  <div className="lg:relative p-2 lg:p-3 rounded-md bg-sky-700">
                    <h2 className="text-lg lg:text-xl xl:text-2xl font-bold text-white uppercase font-primary">
                      September
                    </h2>
                    <ul className="p-4 list-disc list-outside w-36 lg:w-full">
                      <li className="font-semibold text-white font-text">
                        Informative Speech
                      </li>
                      <li className="font-semibold text-white font-text">
                        Technical Presentations
                      </li>
                    </ul>
                    <img
                      className="hidden lg:inline absolute bottom-0 w-20 lg:left-28 xl:left-40 2xl:left-48"
                      src="/images/11.png"
                      alt=""
                    />
                  </div>
                  <div className="lg:relative p-2 lg:p-3 rounded-md bg-orange-400">
                    <h2 className="text-lg lg:text-xl xl:text-2xl font-bold text-white uppercase font-primary">
                      October
                    </h2>
                    <ul className="p-4 list-disc list-outside w-36 lg:w-full">
                      <li className="font-semibold text-white font-text">
                        Inspirational Speech
                      </li>
                      <li className="font-semibold text-white font-text">
                        Emceeing
                      </li>
                    </ul>
                  </div>
                  <div className="lg:relative p-2 lg:p-3 rounded-md bg-orange-400">
                    <h2 className="text-lg lg:text-xl xl:text-2xl font-bold text-white uppercase font-primary">
                      November
                    </h2>
                    <ul className="p-4 list-disc list-outside w-36 lg:w-full">
                      <li className="font-semibold text-white font-text">
                        Online presentations
                      </li>
                      <li className="font-semibold text-white font-text">
                        Hosting, media Presentations
                      </li>
                    </ul>
                    <img
                      className="hidden lg:inline absolute bottom-0 w-20 lg:left-28 xl:left-36 2xl:left-44"
                      src="/images/12.png"
                      alt=""
                    />
                  </div>
                  <div className="lg:relative p-2 lg:p-3 rounded-md bg-orange-400">
                    <h2 className="text-lg lg:text-xl xl:text-2xl font-bold text-white uppercase font-primary">
                      December
                    </h2>
                    <ul className="p-4 list-disc list-outside w-36 lg:w-full">
                      <li className="font-semibold text-white font-text">
                        Debate
                      </li>
                      <li className="font-semibold text-white font-text">
                        Argumentation
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="hidden lg:inline lg:w-1/4">
              <div className="py-20">
                <div className="lg:rotate-90">
                  <h3 className="text-xl font-bold text-center font-text">
                    Foundational elements for all 12 months.
                  </h3>
                  <h2 className="lg:text-3xl xl:text-5xl font-bold text-center font-primary">
                    CORE FOCUS
                  </h2>
                </div>
              </div>
            </div>
            <div className="hidden lg:inline lg:w-1/4">
              <div className="py-20">
                <div className="py-5">
                  <h3 className="text-3xl font-bold">Linguistic Skills</h3>
                  <svg
                    width="300"
                    height="23"
                    viewBox="0 0 387 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M385.584 12.2441C386.169 11.6567 386.166 10.7069 385.579 10.1227L376.007 0.602987C375.419 0.0188074 374.469 0.0214094 373.885 0.608799C373.301 1.19619 373.304 2.14593 373.891 2.73011L382.4 11.1921L373.938 19.7006C373.353 20.288 373.356 21.2377 373.943 21.8219C374.531 22.4061 375.48 22.4035 376.065 21.8161L385.584 12.2441ZM0.908895 13.7373L384.525 12.6863L384.517 9.68631L0.900676 10.7373L0.908895 13.7373Z"
                      fill="#F79E1B"
                    />
                  </svg>
                </div>
                <div className="py-5">
                  <h3 className="text-3xl font-bold">Body Language</h3>
                  <svg
                    width="300"
                    height="23"
                    viewBox="0 0 387 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M385.584 12.2441C386.169 11.6567 386.166 10.7069 385.579 10.1227L376.007 0.602987C375.419 0.0188074 374.469 0.0214094 373.885 0.608799C373.301 1.19619 373.304 2.14593 373.891 2.73011L382.4 11.1921L373.938 19.7006C373.353 20.288 373.356 21.2377 373.943 21.8219C374.531 22.4061 375.48 22.4035 376.065 21.8161L385.584 12.2441ZM0.908895 13.7373L384.525 12.6863L384.517 9.68631L0.900676 10.7373L0.908895 13.7373Z"
                      fill="#F79E1B"
                    />
                  </svg>
                </div>
                <div className="py-5">
                  <h3 className="text-3xl font-bold">
                    Confidence and Stage Fear/ Anxiety
                  </h3>
                  <svg
                    width="300"
                    height="23"
                    viewBox="0 0 387 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M385.584 12.2441C386.169 11.6567 386.166 10.7069 385.579 10.1227L376.007 0.602987C375.419 0.0188074 374.469 0.0214094 373.885 0.608799C373.301 1.19619 373.304 2.14593 373.891 2.73011L382.4 11.1921L373.938 19.7006C373.353 20.288 373.356 21.2377 373.943 21.8219C374.531 22.4061 375.48 22.4035 376.065 21.8161L385.584 12.2441ZM0.908895 13.7373L384.525 12.6863L384.517 9.68631L0.900676 10.7373L0.908895 13.7373Z"
                      fill="#F79E1B"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="block-5" className="mx-3">
        <div class="container mx-auto">
          <div class="flex flex-col lg:flex-row py-14">
            <div class="w-full lg:w-2/3">
              <div>
                <p class="text-3xl lg:text-4xl font-bold py-3">
                  The First-Class Experience!
                </p>
                <p class="pb-5 font-text text-lg">
                  When your child step into their first Speech Excellence
                  Programme class, they will be greeted by our highly capable
                  and qualified team of public speaking trainers, handpicked and
                  trained by our Master Trainer, Darren Tay. We are passionate,
                  committed, and driven to provide your child with the best
                  platform for public speaking and to nurture your child as an
                  immaculate public speaker.
                </p>
                <p class="pb-5 text-lg">
                  For a holistic and all-rounded approach to soft-skills
                  training, your child will experience our 50-50 Rule:
                </p>
              </div>
              <div>
                <div className="mx-auto">
                  <img src="/images/50-50-Theory.png" alt="" />
                </div>
                <div className="flex flex-row mt-5 space-x-2">
                  <div className="p-5 text-lg border-r-2 border-gray-300 border-dashed w-1/2">
                    <p>
                      50% of the class time is focused on an open and engaging
                      sharing of public speaking skills and techniques, peppered
                      with our professional trainers’ personal experiences and
                      engagement activities.
                    </p>
                  </div>
                  <div className="p-5 text-lg w-1/2">
                    <p>
                      50% of the class time is dedicated to providing our
                      students with the safe and constructive space to practice,
                      practice and practice their craft! They receive
                      professional, detailed, and dedicated evaluation every
                      session.
                    </p>
                  </div>
                </div>
                <p class="p-5 font-text text-lg">
                  In their first class alone, your child will be guided on the
                  relevant techniques/content, put in to practice, and receive
                  customised feedback by our professional public speaking
                  trainers. Whether in encouraging a shy student to take the
                  first step or polishing the communication skills of a young
                  speaker, we are ready to provide your child with the necessary
                  growth support in the Speech Excellence Programme.
                </p>
                <div className="w-full bg-slate-100 rounded-xl">
                  <div className="pt-10 pb-5">
                    <h2 className="text-xl font-bold text-center font-primary">
                      What Our Students Have To Say About The Program
                    </h2>
                  </div>
                  <div className="flex flex-col px-5 md:flex-row">
                    <div className="px-5 pb-10 md:w-1/2">
                      <div className="aspect-video">
                        <iframe
                          class="w-full h-full"
                          src="https://www.youtube.com/embed/qHQzBwQAYIc"
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowfullscreen
                        ></iframe>
                      </div>
                      <div className="py-2">
                        <p className="font-bold text-md font-primary">
                          Chloe Lim, 12 years old
                        </p>
                        <p className="font-bold text-md font-primary">
                          Cohort 2019, Speech Excellence Program
                        </p>
                        <p className="font-bold text-md font-primary">
                          3rd Place Winner, National Public Speaking Competition
                          (Pri)
                        </p>
                      </div>
                    </div>
                    <div className="px-5 pb-10 md:w-1/2">
                      <div className="aspect-video">
                        <iframe
                          class="w-full h-full"
                          src="https://www.youtube.com/embed/frF66tI-1Xg"
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowfullscreen
                        ></iframe>
                      </div>
                      <div className="py-2">
                        <p className="font-bold text-md font-primary">
                          Sara Baroni, 12 years old
                        </p>
                        <p className="font-bold text-md font-primary">
                          Cohort 2018, Speech Excellence Program
                        </p>
                        <p className="font-bold text-md font-primary">
                          3rd Place Winner, Plain English Speaking Award 2019
                          <br />
                          Grand Finalist, 2019 National Public Speaking
                          Competition (Pri)
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col px-5 md:flex-row">
                    <div className="px-5 pb-10 md:w-1/2">
                      <img src="/images/kids-5-star-reviews-1.png" alt="" />
                    </div>
                    <div className="px-5 pb-10 md:w-1/2">
                      <img src="/images/kids-5-star-reviews-1.png" alt="" />
                    </div>
                  </div>
                  <div className="flex flex-col px-5 md:flex-row">
                    <div className="px-5 pb-10 md:w-1/2">
                      <img src="/images/kids-5-star-reviews-3.png" alt="" />
                    </div>
                    <div className="px-5 pb-10 md:w-1/2">
                      <img src="/images/kids-5-star-reviews-4.png" alt="" />
                    </div>
                  </div>
                </div>
                <div className="py-5">
                  <p class="text-2xl font-bold py-3 italic">
                    The Enrolment Structure - How Do I Start My Child’s Public
                    Speaking Journey?
                  </p>
                  <p class="pb-5 font-text text-lg">
                    The classes for our Speech Excellence Programme are
                    available at all 3 of our branches around Singapore, in
                    Hougang ActiveSG Sports Centre, SAFRA Tampines, and Bukit
                    Timah Coronation Shopping Plaza. Each class lasts for 1.5
                    hours, with weekly classes held at our branches on
                    Wednesday, Friday, Saturday or Sunday (except Public
                    Holidays, depending on age group):
                  </p>
                </div>
                <div className="py-5">
                  <div className="pb-10">
                    <p class="text-4xl font-bold py-3 font-primary text-center text-red-500">
                      Our Venues and Class Schedule!
                    </p>
                  </div>
                  <div className="flex flex-col lg:flex-row space-y-5 lg:space-y-0">
                    <div className="w-full lg:w-1/3 lg:border-r-2 lg:border-red-500 bg-slate-100 rounded-md lg:bg-white lg:rounded-none">
                      <h2 className="py-2 text-3xl font-bold text-center font-primary text-primary">
                        SAFRA Tampines
                      </h2>
                      <p className="py-2 font-bold text-center font-text">
                        Safra Tampines 1 Tampines Street 92,
                        <br />
                        #01-13B Singapore-528882.
                      </p>
                      <img
                        className="py-2 mx-auto"
                        src="/images/safra-tampines.png"
                        alt=""
                      />
                      <div className="flex flex-row items-center border-b-2 border-red-500">
                        <div className="w-1/3">
                          <h3 className="text-2xl font-bold text-center text-sky-500 font-primary">
                            9 to 12
                          </h3>
                        </div>
                        <div className="w-2/3">
                          <div className="py-2">
                            <div className="px-2 border-l-2 border-sky-500">
                              <p className="text-lg font-bold font-primary">
                                Every Saturday,
                              </p>
                              <p className="text-lg font-text">
                                1:30 pm to 3 pm
                              </p>
                            </div>
                          </div>
                          <div className="py-2">
                            <div className="px-2 border-l-2 border-sky-500">
                              <p className="text-lg font-bold font-primary">
                                Every Sunday,
                              </p>
                              <p className="text-lg font-text">
                                10:30 am to 12 pm
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-row items-center">
                        <div className="w-1/3">
                          <h3 className="text-2xl font-bold text-center text-sky-800 font-primary">
                            13 to 18
                          </h3>
                        </div>
                        <div className="w-2/3">
                          <div className="py-2">
                            <div className="px-2 border-l-2 border-sky-800">
                              <p className="text-lg font-bold font-primary">
                                Every Saturday,
                              </p>
                              <p className="text-lg font-text">
                                1:30 pm to 3 pm
                              </p>
                            </div>
                          </div>
                          <div className="py-2">
                            <div className="px-2 border-l-2 border-sky-800">
                              <p className="text-lg font-bold font-primary">
                                Every Sunday,
                              </p>
                              <p className="text-lg font-text">
                                12:30 pm to 2 pm
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-full lg:w-1/3 lg:border-r-2 lg:border-red-500 bg-slate-100 rounded-md lg:bg-white lg:rounded-none">
                      <h2 className="py-2 text-3xl font-bold text-center font-primary text-primary">
                        Bukit Timah
                      </h2>
                      <p className="py-2 font-bold text-center font-text">
                        587 Bukit Timah Road, Coronation Shopping
                        <br />
                        Plaza #02-23, Singapore 269707.
                      </p>
                      <img
                        className="py-2 mx-auto"
                        src="/images/bukit-timah.png"
                        alt=""
                      />
                      <div className="flex flex-row items-center border-b-2 border-red-500">
                        <div className="w-1/3">
                          <h3 className="text-2xl font-bold text-center text-red-500 font-primary">
                            7 to 8
                          </h3>
                        </div>
                        <div className="w-2/3">
                          <div className="py-4">
                            <div className="px-2 border-l-2 border-red-500">
                              <p className="text-lg font-bold font-primary">
                                Every Friday,
                              </p>
                              <p className="text-lg font-text">
                                4:30 pm to 6 pm
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-row items-center border-b-2 border-red-500">
                        <div className="w-1/3">
                          <h3 className="text-2xl font-bold text-center text-sky-500 font-primary">
                            9 to 12
                          </h3>
                        </div>
                        <div className="w-2/3">
                          <div className="py-4">
                            <div className="px-2 border-l-2 border-sky-500">
                              <p className="text-lg font-bold font-primary">
                                Every Saturday/ Sunday,
                              </p>
                              <p className="text-lg font-text">
                                10:30 am to 12 pm
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-row items-center">
                        <div className="w-1/3">
                          <h3 className="text-2xl font-bold text-center text-sky-800 font-primary">
                            13 to 18
                          </h3>
                        </div>
                        <div className="w-2/3">
                          <div className="py-4">
                            <div className="px-2 border-l-2 border-sky-800">
                              <p className="text-lg font-bold font-primary">
                                Every Saturday/ Sunday,
                              </p>
                              <p className="text-lg font-text">
                                12:30 pm to 2 pm
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-full lg:w-1/3 lg:border-r-2 lg:border-red-500 bg-slate-100 rounded-md lg:bg-white lg:rounded-none">
                      <h2 className="py-2 text-3xl font-bold text-center font-primary text-primary">
                        Hougang
                      </h2>
                      <p className="py-2 font-bold text-center font-text">
                        93 Hougang Avenue 4, #01-03,
                        <br />
                        Singapore 538832.
                      </p>
                      <img
                        className="py-2 mx-auto"
                        src="/images/hougang.png"
                        alt=""
                      />
                      <div className="flex flex-row items-center border-b-2 border-red-500">
                        <div className="w-1/3">
                          <h3 className="text-2xl font-bold text-center text-red-500 font-primary">
                            7 to 8
                          </h3>
                        </div>
                        <div className="w-2/3">
                          <div className="py-4">
                            <div className="px-2 border-l-2 border-red-500">
                              <p className="text-lg font-bold font-primary">
                                Every Wednesday,
                              </p>
                              <p className="text-lg font-text">
                                4:30 pm to 6 pm
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-row items-center border-b-2 border-red-500">
                        <div className="w-1/3">
                          <h3 className="text-2xl font-bold text-center text-sky-500 font-primary">
                            9 to 12
                          </h3>
                        </div>
                        <div className="w-2/3">
                          <div className="py-4">
                            <div className="px-2 border-l-2 border-sky-500">
                              <p className="text-lg font-bold font-primary">
                                Every Saturday/ Sunday,
                              </p>
                              <p className="text-lg font-text">
                                10:30 am to 12 pm
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-row items-center">
                        <div className="w-1/3">
                          <h3 className="text-2xl font-bold text-center text-sky-800 font-primary">
                            13 to 18
                          </h3>
                        </div>
                        <div className="w-2/3">
                          <div className="py-4">
                            <div className="px-2 border-l-2 border-sky-800">
                              <p className="text-lg font-bold font-primary">
                                Every Sunday,
                              </p>
                              <p className="text-lg font-text">
                                12:30 pm to 2 pm
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full lg:w-1/3"></div>
          </div>
        </div>
      </section>

      <section className="mx-3">
        <div className="container mx-auto mb-20">
          <div className="w-full lg:w-2/3">
            <div className="p-5">
              <h2 className="text-4xl font-bold text-center text-primary font-primary">
                Key Enrollment Details
              </h2>
            </div>
            <div className="flex flex-row justify-center w-full lg:w-1/2 mx-auto border-4 border-rose-500">
              <div className="p-5">
                <img src="/images/psa-vertical-logo.png" alt="" />
              </div>
              <div className="p-5">
                <h2 className="text-5xl font-bold text-right text-blue-700 font-primary">
                  About our
                  <br />
                  Programme
                </h2>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row m-14 space-y-5 lg:space-y-0">
              <div className="w-full lg:w-1/3 p-2 text-center lg:border-r lg:border-red-400 lg:border-dashed">
                <span className="text-purple-400 text-8xl material-symbols-rounded">
                  payments
                </span>
                <h3 className="py-2 text-lg font-bold text-primary">Fees</h3>
                <p className="text-md font-text">
                  <span className="font-bold text-red-500 font-text">$260</span>{" "}
                  for 4 lessons (7 to 8 years old)
                </p>
                <p className="text-md font-text">
                  <span className="font-bold text-red-500 font-text">$270</span>{" "}
                  for 4 lessons (9 to 12 years old)
                </p>
                <p className="text-md font-text">
                  <span className="font-bold text-red-500 font-text">$290</span>{" "}
                  for 4 lessons (13 to 18 years old)
                </p>
                <p className="text-md font-text">
                  (adjusted based on the actual number of lessons each month -
                  full breakdown upon enrolment confirmation)
                </p>
              </div>

              <div className="w-full lg:w-1/3 p-2 text-center lg:border-r lg:border-red-400 lg:border-dashed">
                <span className="text-red-600 text-8xl material-symbols-rounded">
                  forum
                </span>
                <h3 className="py-2 font-bold text-md text-primary">
                  3-month Minimum Commitment
                </h3>
                <p className="text-md font-text">
                  Students must enroll for a minimum of 3 months to ensure a
                  consistent learning experience (for students who choose to pay
                  monthly, a <b>$100 deposit</b> will be required for the 1st
                  month's fees, to offset the 3rd month's fees)
                </p>
              </div>

              <div className="w-full lg:w-1/3 p-2 text-center lg:border-r lg:border-red-400 lg:border-dashed">
                <span className="text-cyan-600 text-8xl material-symbols-rounded">
                  group
                </span>
                <h3 className="py-2 font-bold text-md text-primary">
                  Maximum Class Size
                </h3>
                <p className="text-md font-text">
                  The maximum class size is 12 students to ensure a balance
                  between group training and individualised focus between the
                  Coach and the students and safe distancing management at our
                  centres.
                </p>
              </div>
            </div>
            <p class="pb-5 text-xl text-center">
              Join us for our no-obligation trial class where your child will
              get to join an on-going class, enjoy the learning experience, and
              receive feedback from our professional trainers:
            </p>
            <p class="text-center py-5">
              <a
                class="block font-primary text-white font-bold lg:text-xl text-lg bg-btnColor px-2 lg:px-6 py-2 lg:py-4 rounded-xl"
                href="#"
              >
                Click Here to Get a 1.5 Hour Class for Just $29 (normally $75)
              </a>
            </p>
          </div>
          <div className="w-1/3"></div>
        </div>
      </section>

      <Footer />
    </>
  );
}
