import "./index.css";
import React from "react";
import Header from "./components/Header";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import About from "./pages/about";
import MediaReleases from "./pages/media-releases";
import CoursesAndPrograms from "./pages/courses-and-programs";
import DarrenTay from "./pages/master-trainer";
import SeniorTrainers from "./pages/senior-trainers-trainers";
import RegularWeeklyClasses from "./pages/regular-weekly-classes-for-kids";
import HolidayMasterclasses from "./pages/holiday-camps-masterclasses";
import GroupClassesAdults from "./pages/groupclassesadults";
import PresentationSkillsAdult from "./pages/presentation-skills-training-adult";
import PremiumOneToOne from "./pages/premium-1-to-1";
import CorporateBusinessTraining from "./pages/corporate-business-training";
import SchoolEnrichmentProgrammes from "./pages/school-enrichment-programmes";
import Competitions from "./pages/competition";
import HallOfFame from "./pages/hall-of-fame";
import TermsConditions from "./pages/terms-and-conditions";
import DataPolicy from "./pages/our-data-privacy-policy";
import Blog from "./pages/blog";
import RecipeForSuccess from "./pages/recipe-for-success-what-goes-into-a-public-speaking-training-programme-for-kids";
import Contact from "./pages/contact";

function App() {
  return (
    <>
      <Header />

      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="about" element={<About />}></Route>
        <Route path="media" element={<MediaReleases />}></Route>
        <Route
          path="courses-and-programs"
          element={<CoursesAndPrograms />}
        ></Route>
        <Route path="master-trainer" element={<DarrenTay />}></Route>
        <Route
          path="senior-trainers-trainers"
          element={<SeniorTrainers />}
        ></Route>
        <Route
          path="group-classes-students-age-7-18"
          element={<RegularWeeklyClasses />}
        ></Route>
        <Route
          path="school-holiday-public-speaking-program-for-children"
          element={<HolidayMasterclasses />}
        ></Route>
        <Route
          path="courses-and-programs/group-classes-for-adults"
          element={<GroupClassesAdults />}
        ></Route>
        <Route
          path="presentation-skills-training-course-by-world-champion"
          element={<PresentationSkillsAdult />}
        ></Route>
        <Route
          path="courses-and-programs/premium-1-to-1-public-speaking-coaching"
          element={<PremiumOneToOne />}
        ></Route>
        <Route
          path="corporate-public-speaking-training-workshop"
          element={<CorporateBusinessTraining />}
        ></Route>
        <Route
          path="public-speaking-training-for-schools-workshop"
          element={<SchoolEnrichmentProgrammes />}
        ></Route>
        <Route
          path="national-public-speaking-competition"
          element={<Competitions />}
        ></Route>
        <Route
          path="terms-and-conditions"
          element={<TermsConditions />}
        ></Route>
        <Route path="our-data-privacy-policy" element={<DataPolicy />}></Route>
        <Route path="hall-of-fame" element={<HallOfFame />}></Route>
        <Route path="contact" element={<Contact />}></Route>
        <Route path="category/blog" element={<Blog />}></Route>
        <Route path="recipe-for-success-what-goes-into-a-public-speaking-training-programme-for-kids" element={<RecipeForSuccess />}></Route>
      </Routes>
    </>
  );
}

export default App;
