import Header from "../components/Header";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";

export default function PremiumOneToOne() {
  return (
    <>
      <section id="breadcrumb" className="bg-[#F5F5FC] py-1">
        <div className="container mx-auto">
          <div className="flex flex-row justify-between mx-3">
            <div>CUSTOMISED 1-TO-1 PRIVATE TRAINING</div>
            <div>Home / Courses & Programs / Premium 1-to-1</div>
          </div>
        </div>
      </section>

      <section id="block-1" className="mx-3">
        <div class="container mx-auto py-20">
          <div class="flex flex-col lg:flex-row pt-5 space-x-10">
            <div class="w-full lg:w-2/3">
              <h1 class="font-primary text-3xl font-bold text-primary uppercase">
                Premium One-to-One Public Speaking Training (for Kids, Adults or
                Corporate Clients)
              </h1>
              <h2 class="font-primary text-2xl font-bold text-primary uppercase py-5">
                Customised Training for your Learning Needs
              </h2>
              <div>
                <p class="pb-5">
                  The Public Speaking Academy has an extensive record of
                  providing customised public speaking training for clients of
                  all ages, background and learning needs. Since 2009, our team
                  of professional and highly qualified public speaking trainers
                  have trained and coached clients (on a one-to-one basis), with
                  learning plans tailored to meet our clients’ diverse learning
                  objectives. Through the premium one-to-one public speaking
                  training, our clients enjoy unparalleled privacy,
                  individualised attention and rigorous development from our
                  professional trainers
                </p>
                <p class="pb-5">
                  So far, we have trained clients from as young as 7 years old,
                  to prepare for national public speaking competitions, to
                  corporate executives / senior management professionals,
                  looking to improve their business presentations skills (both
                  Singapore and abroad). For that customised and dedicated
                  approach to learning, we welcome you to reach out to us to
                  discuss your unique public speaking learning journey!
                </p>
                <p class="pb-5">
                  <strong>
                    Our Customised Public Speaking Learning Journey
                  </strong>
                </p>
                <p class="pb-5">
                  In preparing our customised public speaking learning journey
                  for you, the Public Speaking Academy draws upon on our
                  extensive experience, professional training team and our
                  established syllabus (as registered with the Ministry of
                  Education). Beyond this, our premium one-to-one public
                  speaking training also offers our clients the wide flexibility
                  of selecting their learning objectives, preferred timing/venue
                  or their pick from our professional trainers with their
                  diverse background. We provide value to our one-to-one clients
                  through:
                </p>
                <ul className="p-5 list-disc list-outside">
                  <li className="pb-5 text-lg font-text">
                    <strong>
                      Designing a Structured & Customised Solution
                    </strong>{" "}
                    – we believe in engaging with our one-to-one clients first
                    to understand their specific learning objectives and how
                    best to design and structure the best-fit public speaking
                    training programme
                  </li>
                  <li className="pb-5 text-lg font-text">
                    <strong>Communications Skills Sharing & Practice</strong> –
                    we draw upon our experience and expertise in presenting a
                    suite of effective communication skills and techniques,
                    through demonstration and practise with our professional
                    trainers
                  </li>
                  <li className="pb-5 text-lg font-text">
                    <strong>
                      Rigorous, but Safe Platform for Public Speaking Practice
                    </strong>{" "}
                    – we provide our clients with a challenging, but safe,
                    platform to practise – be it a high-impact business
                    presentation or rehearsing for a national public speaking
                    competition
                  </li>
                  <li className="pb-5 text-lg font-text">
                    <strong>
                      Immediate, Constructive and Targeted Feedback
                    </strong>{" "}
                    – we provide immediate, constructive and targeted feedback
                    for each session for a thorough and focused growth in their
                    public speaking abilities
                  </li>
                </ul>

                <p class="pb-5">
                  Our one-to-one clients have approached us for a variety of
                  public speaking and coaching services, including:
                </p>
                <div className="flex flex-row my-10">
                  <div className="w-1/2">
                    <div className="flex flex-row items-center my-3">
                      <div>
                        <span class="material-symbols-rounded bg-orange-600 text-white p-2 rounded-full">
                          group
                        </span>
                      </div>
                      <div className="ml-4 font-bold">Seminar Talks</div>
                    </div>

                    <div className="flex flex-row items-center my-3">
                      <div>
                        <span class="material-symbols-rounded bg-orange-600 text-white p-2 rounded-full">
                          emoji_people
                        </span>
                      </div>
                      <div className="ml-4 font-bold">
                        High-stakes business presentations
                      </div>
                    </div>

                    <div className="flex flex-row items-center my-3">
                      <div>
                        <span class="material-symbols-rounded bg-orange-600 text-white p-2 rounded-full">
                          psychology
                        </span>
                      </div>
                      <div className="ml-4 font-bold">Workshop speeches</div>
                    </div>

                    <div className="flex flex-row items-center my-3">
                      <div>
                        <span class="material-symbols-rounded bg-orange-600 text-white p-2 rounded-full">
                          diamond
                        </span>
                      </div>
                      <div className="ml-4 font-bold">Wedding toasts</div>
                    </div>

                    <div className="flex flex-row items-center my-3">
                      <div>
                        <span class="material-symbols-rounded bg-orange-600 text-white p-2 rounded-full">
                          clinical_notes
                        </span>
                      </div>
                      <div className="ml-4 font-bold">Keynote addresses</div>
                    </div>
                  </div>
                  <div className="w-1/2">
                    <div className="flex flex-row items-center my-3">
                      <div>
                        <span class="material-symbols-rounded bg-orange-600 text-white p-2 rounded-full">
                          lab_research
                        </span>
                      </div>
                      <div className="ml-4 font-bold">
                        Speech contest preparations
                      </div>
                    </div>

                    <div className="flex flex-row items-center my-3">
                      <div>
                        <span class="material-symbols-rounded bg-orange-600 text-white p-2 rounded-full">
                          trending_up
                        </span>
                      </div>
                      <div className="ml-4 font-bold">Pitches</div>
                    </div>

                    <div className="flex flex-row items-center my-3">
                      <div>
                        <span class="material-symbols-rounded bg-orange-600 text-white p-2 rounded-full">
                          insert_chart
                        </span>
                      </div>
                      <div className="ml-4 font-bold">Board presentations</div>
                    </div>

                    <div className="flex flex-row items-center my-3">
                      <div>
                        <span class="material-symbols-rounded bg-orange-600 text-white p-2 rounded-full">
                          diversity_2
                        </span>
                      </div>
                      <div className="ml-4 font-bold">
                        Interpersonal communication scenarios
                      </div>
                    </div>

                    <div className="flex flex-row items-center my-3">
                      <div>
                        <span class="material-symbols-rounded bg-orange-600 text-white p-2 rounded-full">
                          diversity_3
                        </span>
                      </div>
                      <div className="ml-4 font-bold">
                        General development (in the area of effective
                        communication)
                      </div>
                    </div>
                  </div>
                </div>
                <p class="pb-5">
                  Whether you are looking for focused feedback for a high-stakes
                  business presentation or preparing for a speech before a large
                  audience, our premium one-to-one public speaking training is
                  perfect for you! Our pricing scaffold is likewise transparent
                  and reasonable – as we would need to customise the public
                  speaking training programme to match your learning needs, we
                  welcome you to reach out to us for a discussion and a
                  quotation!
                  <br />
                  The Public Speaking Academy looks forward to being there
                  through every step of your public speaking and
                  confidence-building journey!
                </p>
              </div>
            </div>
            <div className="hidden lg:inline w-1/3 px-5 pt-5 mb-10 border border-dashed rounded-lg">
              <Sidebar />
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
