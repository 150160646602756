import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";

export default function PresentationSkillsAdult() {
  return (
    <>
      <section id="breadcrumb" className="bg-[#F5F5FC] py-1">
        <div className="container mx-auto">
          <div className="flex flex-row justify-between mx-3">
            <div>S’PEAK PERFORMANCE PROGRAMME (ADULTS)</div>
            <div>
              Home / Courses & Programs / Public Speaking for Adults / Intensive
              Masterclasses/Bootcamps
            </div>
          </div>
        </div>
      </section>

      <section id="block-1" className="mx-3">
        <div class="container mx-auto py-20">
          <div class="flex flex-col lg:flex-row space-x-10 pt-5">
            <div class="w-full lg:w-2/3">
              <h1 class="font-primary text-3xl font-bold uppercase text-primary">
                S’Peak Performance Programme (Adults)
              </h1>
              <h2 class="py-5 font-primary text-2xl font-bold uppercase text-primary">
                2-Day Intensive, Masterclass in Public Speaking & Presentations
              </h2>
              <div>
                <img
                  src="/images/public-speaking-course-for-adults-public-speaking-courses-for-adults-public-speaking-for-adults-1-3.png"
                  alt=""
                />
                <p class="py-5 font-text text-lg">
                  “I don’t need public speaking skills.” From the biggest stage
                  to the smallest room, communication and presentation skills
                  remain an important part of our day-to-day routine. You may be
                  delivering a speech before a large audience, giving a business
                  presentation to the board of directors, or motivating your
                  team – all these scenarios require a precise, consistent, and
                  well-researched approach. We know that you want to be that
                  charismatic, persuasive, and engaging presenter. If you are
                  still looking for that course to improve your business
                  presentation, communication, and public speaking skills, look
                  no further than our S’Peak Performance Programme for adults!
                </p>
                <img
                  src="/images/public-speaking-course-for-adults-public-speaking-courses-for-adults-public-speaking-for-adults-2-2.png"
                  alt=""
                />

                <p className="pt-5 pb-5 text-xl font-bold">
                  Introducing our S’Peak Performance Programme (for Adults)
                </p>
                <p className="text-lg font-text">
                  The S’Peak Performance Programme (Adults) is a 2-day intensive
                  master-class that offers a deep-dive into public speaking and
                  communication techniques, tailored to the modern individual.
                  We have curated the core components of the programme to cover
                  topics that are essential to your day-to-day at work or home.
                  The S’Peak Performance Programme is helmed personally by our
                  Master Trainer and World Champion of Public Speaking 2016,
                  Darren Tay (assisted by Senior Trainer). The programme is
                  designed with these in mind:
                </p>
                <ul className="p-5 list-disc list-outside">
                  <li className="text-lg font-text">
                    Versatile, Relevant, and Implementable Techniques:
                    communication techniques that are immediately relevant to
                    your day-to-day settings (e,g,, business presentations,
                    persuasion)
                  </li>
                  <li className="text-lg font-text">
                    All-Rounded Learning Experience: pathway to advanced soft
                    skills such as leadership, persuasion, or even conflict
                    management, making you an all-rounded presenter
                  </li>
                  <li className="text-lg font-text">
                    World Champion Experience: learning experience designed by
                    our training team, led by Darren Tay, Singapore’s first
                    World Champion of Public Speaking!
                  </li>
                </ul>

                <p class="py-5 font-text text-lg">
                  Learn and practise effective communication and presentation
                  skills in a safe, constructive, and engaging learning
                  environment.
                </p>
                <p className="pt-5 pb-5 text-3xl font-bold text-center">
                  The Programme Syllabus
                </p>

                <section>
                  <div class="flex flex-col lg:flex-row space-y-10 lg:space-y-0 lg:space-x-10 my-5">
                    <div className="w-full lg:w-1/2">
                      <img src="/images/point-1.png" alt="" />
                      <h1 class="text-3xl font-bold mt-4">
                        Conquering Stage Fright & Enhancing Your Stage
                        <br />
                        Charisma
                      </h1>
                      <p class="font-bold mt-4 text-lg">
                        How do we turn stage fright into stage might? Is
                        itpossible to get rid of stage anxiety entirely? Anxiety
                        is auniversal human emotion. This means that while we
                        cannot get rid of anxiety entirely (this is the same for
                        even the best speakers in the world), we can change our
                        relationship with it and turn stage fright into stage
                        might.
                      </p>
                      <ul class="font-bold mt-4 text-lg list-disc list-inside">
                        Here's some things you'll learn:
                        <li>
                          Effective power poses you can use to overcome stage
                          <br />
                          fright
                        </li>
                        <li>
                          step-by-step visualization method to be consistently
                          <br />
                          confident
                        </li>
                        <li>
                          How to rehearse & practice like the top professional
                          <br />
                          speakers
                        </li>
                      </ul>
                    </div>
                    <div className="w-full lg:w-1/2">
                      <img src="/images/point-2.png" alt="" />
                      <h1 class="font-bold mt-4 text-3xl">
                        Delivering A Winning Business Presentation
                      </h1>
                      <p class="font-bold mt-4 text-lg">
                        Is there a blueprint to winning business presentation?
                        <br />
                        Can I become a proficient speaker even though I am an
                        introvert?
                        <br />
                        The answer is: Yes you can!
                      </p>
                      <ul class="font-bold mt-4 text-lg list-disc list-inside">
                        Here some things you'll learn:
                        <li>How to compose an attention-grabbing story</li>
                        <li>
                          The secrets to delivering powerful powerpoint
                          <br />
                          presentation without reading off the slides
                        </li>
                        <li>
                          The "8-step Edge of the seat" formula that keeps
                          peoples listening attentively plus 2 speech genres
                          (persuasive and informative speech)
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="flex flex-col lg:flex-row space-y-10 lg:space-y-0 lg:space-x-10 my-5">
                    <div className="w-full lg:w-1/2">
                      <img src="/images/point-3.png" alt="" />
                      <h1 class="text-3xl font-bold mt-4">
                        Top Body Language Secrets
                      </h1>
                      <p class="font-bold mt-4 text-lg">
                        Studies have shown that 70% of communication is body
                        language! This is how important non-verbal communication
                        is.
                      </p>
                      <ul class="font-bold mt-4 text-lg list-disc list-inside">
                        Here's some things you'll learn:
                        <li>
                          Hand gestures,power poses, stage positioning fright
                        </li>
                        <li>
                          How to have stage presence with proper eye contact
                          (learn about the wide angle of attack and eye contact
                          distribution)
                        </li>
                        <li>
                          The power of incorporating pauses in your presentation
                        </li>
                        <li>Facial expressions you should avoid using</li>
                      </ul>
                    </div>
                    <div className="w-full lg:w-1/2">
                      <img src="/images/point-4.png" alt="" />
                      <h1 class="font-bold mt-4 text-3xl">
                        Impromptu Speaking
                      </h1>
                      <p class="font-bold mt-4 text-lg">
                        How to think fast on your feet if you need to speak at
                        the last min:
                      </p>
                      <ul class="font-bold mt-4 text-lg list-disc list-inside">
                        Here some things you'll learn:
                        <li>
                          The P.E.E.L & interrogative pronouns formula that you
                          can use if you've ran out of ideas
                        </li>
                        <li>
                          The P.P.F and the Pendulum model that helps you to
                          speak during 1-on-1 or to a group
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="flex flex-col lg:flex-row space-y-10 lg:space-y-0 lg:space-x-10 my-5 justify-center">
                    <div className="w-full lg:w-1/2">
                      <img src="/images/point-5.png" alt="" />
                      <h1 class="text-3xl font-bold mt-4">
                        Linguistics & Vocal
                      </h1>
                      <ul class="font-bold mt-4 text-lg list-disc list-inside">
                        <li>
                          How to make use of vocal/tonal speech patterns to make
                          yourself understood faster and better
                        </li>
                        <li>
                          Identify and apply the techniques to avoid commonly
                          mis-pronounced and mis-enunciated words
                        </li>
                        <li>
                          Using rhetorical questions and literacy devices to ace
                          your next presentation
                        </li>
                      </ul>
                    </div>
                  </div>
                </section>

                <p className="mt-10 mb-5 text-xl italic font-bold">
                  The Programme Details - How Do I Sign Up?
                </p>

                <div className="p-5 bg-orange-200 rounded-md ">
                  <h3 class="mb-5 text-center font-primary text-xl font-bold text-primary">
                    Upcoming Cohort
                  </h3>
                  <p className="text-lg text-center ">
                    18 & 19 May 2023 (Thursday & Friday), 9.00 am to 6.00 pm on
                    both days (Note: No meals provided)
                  </p>

                  <p className="font-bold text-center text-md">
                    (doors open for attendance from 8.30 to 9 am for both
                    sessions, with class starting strictly at 9 am)
                  </p>
                </div>

                <div className="p-5 my-10 bg-gray-100 rounded-md ">
                  <h3 class="mb-5 text-center font-primary text-xl font-bold text-primary">
                    Price Details
                  </h3>
                  <p className="text-lg py-2">
                    2-day intensive, knowledge-packed, ample practice
                    opportunities training camp/ workshop, conducted personally
                    by the World Champion of Public Speaking Darren Tay
                    (assisted by our Senior Trainer), learning materials (e.g.,
                    printed materials, evaluation forms, certificates of
                    completion) provided – gain effective communication and
                    business presentation skills you can apply immediately!
                  </p>
                  <p className="text-lg py-2">
                    In addition, we will extend free access to our exclusive
                    Facebook Mastermind group (worth $297) – continued access to
                    your World Champion Trainer where you can ask questions
                    directly, AFTER the program, so as to further support you on
                    your journey to becoming an effective presenter!
                  </p>
                  <p className="text-lg font-bold py-2">
                    All in all, we are offering a limited-seats-only rate of –
                    S$997 nett. Register NOW to secure your seat as we go by a
                    first-secured-first-serve basis.
                  </p>
                </div>

                <p className="mt-10 mb-5 text-xl font-bold text-center">
                  Other Key Programme Details
                </p>
                <div className="flex flex-row items-center border-t border-gray-200">
                  <div className="w-1/3 p-5 font-bold text-center bg-white">
                    Where is the class conducted?
                  </div>
                  <div className="w-2/3 p-5 bg-gray-100">
                    <b>SAFRA TAMPINES BRANCH</b>
                    <br />
                    Public Speaking Academy @ Tampines <br />
                    1A Tampines Street 92 #01-13B <br />
                    SAFRA Tampines <br />
                    Singapore-528882.
                  </div>
                </div>
                <div className="flex flex-row items-center border-t border-gray-200">
                  <div className="w-1/3 p-5 font-bold text-center bg-white">
                    What is the class size?
                    <br />
                    Can I attend a trial?
                  </div>
                  <div className="w-2/3 p-5 bg-gray-100">
                    Maximum class size of 16 – 18 students – as this is an
                    intensive programme with limited capacity, there is no trial
                    class. Likewise, only enrolled students will be allowed to
                    sit in for the programme to ensure a constructive and safe
                    learning environment.
                  </div>
                </div>
                <div className="flex flex-row items-center border-t border-gray-200">
                  <div className="w-1/3 p-5 font-bold text-center bg-white">
                    Who are the Trainers?
                  </div>
                  <div className="w-2/3 p-5 bg-gray-100">
                    The programme will be helmed by our Principal/Master Trainer
                    Mr Darren Tay and our Senior Trainer (with each Trainer
                    taking one day content load each).
                  </div>
                </div>
                <div className="flex flex-row items-center border-t border-gray-200">
                  <div className="w-1/3 p-5 font-bold text-center bg-white">
                    What if I miss a class or need to leave early?
                  </div>
                  <div className="w-2/3 p-5 bg-gray-100">
                    There is no replacement for the missed session or a
                    refund/pro-rate for the session/portions missed. We
                    recommend learners to confirm their availability for the
                    full programme before signing up (you may instead consider
                    our regular public speaking programme).
                  </div>
                </div>
                <div className="flex flex-row items-center border-t border-gray-200">
                  <div className="w-1/3 p-5 font-bold text-center bg-white">
                    How are your classes conducted with the present COVID-19
                    situation?
                  </div>
                  <div className="w-2/3 p-5 bg-gray-100">
                    Our programmes are conducted with proper clearance from the
                    relevant Government authorities (Ministry of Education and
                    EnterpriseSG). We also abide by our Safety Management Plan,
                    which measures such as safe distancing, mandatory
                    mask-wearing, temperature-taking etc. (feel free to request
                    for a copy of our Safety Management Plan).
                    <br />
                    <br /> In the event of any COVID-19 measures, legislation,
                    or any directive from the Government disrupting the conduct
                    of the programme, there will be no refund of the fees paid.
                    Instead, the programme will be postponed to another
                    date/time (as per our Terms & Conditions in our Registration
                    Form).
                  </div>
                </div>
              </div>

              <div className="my-10 text-center">
                <a
                  className="px-6 py-4 text-xl font-bold text-white rounded-xl bg-btnColor font-primary"
                  href="#"
                >
                  CLICK HERE TO ENROLL NOW
                </a>
              </div>
              <p class="py-5 font-text text-lg">
                If you are ready to sign up, simply click the button above and
                the registration/payment will be processed by our platform (feel
                free to reach out to us for any queries!)
              </p>
              <p className="mt-4 mb-2 text-2xl font-bold text-center">
                Still undecided? Hear more about our S’Peak Performance
                Programme!
              </p>
              <p className="my-2 text-lg font-bold text-center">
                Hear from our past learners!
              </p>
              <div className="flex flex-col my-10 md:space-x-5 md:flex-row">
                <div className="w-full lg:px-5 pb-10 md:w-1/2">
                  <div className="aspect-video">
                    <iframe
                      class="w-full h-full"
                      src="https://www.youtube.com/embed/3vHFhwNnhrM"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
                <div className="w-full lg:px-5 pb-10 md:w-1/2">
                  <div className="aspect-video">
                    <iframe
                      class="w-full h-full"
                      src="https://www.youtube.com/embed/FfXxW6ALzuw"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
              </div>

              <div className="flex flex-row my-10 space-x-5">
                <div className="w-1/2">
                  <img
                    src="/images/For-slider-1-Presentation-Skills-Training-Course-Public-Speaking-Masterclass-Workshop-World-Champion-Darren-Tay-Success-Testimonial-1024x576.png"
                    alt=""
                  />
                </div>
                <div className="w-1/2">
                  <img
                    src="/images/For-slider-2-Presentation-Skills-Training-Course-Public-Speaking-Masterclass-Workshop-World-Champion-Darren-Tay-Testimonial-1024x576.png"
                    alt=""
                  />
                </div>
              </div>

              <div className="flex flex-row my-10 space-x-5">
                <div className="w-1/2">
                  <img
                    src="/images/For-slider-3-Presentation-Skills-Training-Course-Public-Speaking-program-Workshop-World-Champion-Darren-Tay-Testimonial-1024x576.png"
                    alt=""
                  />
                </div>
                <div className="w-1/2">
                  <img
                    src="/images/For-slider-4-Presentation-Skills-Training-Course-Public-Speaking-Masterclass-Workshop-World-Champion-Darren-Testimonial-Success-1024x576.png"
                    alt=""
                  />
                </div>
              </div>
              <p className="mt-4 mb-2 text-2xl font-bold text-center">
                Know more about our track record!
              </p>
              <img
                className="my-4 text-center"
                src="/images/image showing satisfied corporate clients of s'peak performance public speaking skills training program-min.png"
                alt=""
              />
              <img
                className="my-4 text-center"
                src="/images/public-speaking-course-for-adults-public-speaking-courses-for-adults-public-speaking-for-adults-6.png"
                alt=""
              />
              <img
                className="my-4 text-center"
                src="/images/public-speaking-course-for-adults-public-speaking-courses-for-adults-public-speaking-for-adults-7.png"
                alt=""
              />

              <div className="my-10 text-center">
                <a
                  className="px-6 py-4 text-xl font-bold text-white rounded-xl bg-btnColor font-primary"
                  href="#"
                >
                  CLICK HERE TO ENROLL NOW
                </a>
              </div>
              <p className="mt-10 text-2xl font-bold text-center">
                Join us now and elevate your public speaking journey!
              </p>
            </div>
            <div className="hidden lg:inline lg:w-1/3 px-5 pt-5 mb-10 border border-dashed rounded-lg">
              <Sidebar />
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
