import Footer from "../components/Footer";
import "../index.css";
import { NavLink } from "react-router-dom";
//import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Pagination, Navigation, Mousewheel, Keyboard } from "swiper";
export default function Home() {
  return (
    <>
      <section id="introduction">
        <div className="container mx-auto">
          <div className="flex flex-col py-10 mx-3 lg:flex-row">
            <div className="w-full p-4 lg:w-1/2 lg:p-0">
              <p className="my-3 text-lg font-normal text-indigo-800 font-primary">
                Are you ready to bring your public speaking skills to the next
                level?
              </p>
              <h1 className="my-3 font-primary text-3xl font-bold leading-9 text-primary lg:text-4xl lg:leading-[50px]">
                Master effective communication skills with the World Champion
                Public Speaking Course in Singapore!
              </h1>
              <p className="my-3 text-lg leading-7 text-justify font-text text-textColor lg:text-left lg:text-xl">
                Have you ever wondered how top professional speakers can express
                themselves and impress others effortlessly through their
                impactful presentations? Are you looking to improve your
                communication confidence in day-to-day settings? Step into the
                spotlight and explore the public speaking skills and techniques
                we offer ito improve your credibility, capability, and
                competency as a confident public speaker and communicator.
              </p>
              <a
                href="#modal-read-more"
                className="px-3 py-1 text-xs text-white uppercase rounded-full cursor-pointer bg-iconColor2"
              >
                Read More
              </a>
              <div className="flex flex-col my-5 lg:my-10 lg:flex-row">
                <div className="flex flex-row my-5 space-x-3 lg:my-0 lg:w-1/2">
                  <div className="min-h-[60px] min-w-[60px] border-2 border-[#f2f2f2] bg-white">
                    <svg
                      className="mx-auto my-1"
                      width="44"
                      height="44"
                      viewBox="0 0 44 44"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.16684 24.1632V29.3149C9.16684 30.6532 9.90018 31.8999 11.0735 32.5415L20.2402 37.5465C21.3402 38.1515 22.6602 38.1515 23.7602 37.5465L32.9268 32.5415C34.1002 31.8999 34.8335 30.6532 34.8335 29.3149V24.1632L23.7602 30.2132C22.6602 30.8182 21.3402 30.8182 20.2402 30.2132L9.16684 24.1632ZM20.2402 6.45318L4.78518 14.8865C3.52018 15.5832 3.52018 17.4165 4.78518 18.1132L20.2402 26.5465C21.3402 27.1515 22.6602 27.1515 23.7602 26.5465L38.5002 18.4982V29.3332C38.5002 30.3415 39.3252 31.1665 40.3335 31.1665C41.3418 31.1665 42.1668 30.3415 42.1668 29.3332V17.5815C42.1668 16.9032 41.8002 16.2982 41.2135 15.9682L23.7602 6.45318C23.2185 6.1643 22.6141 6.01318 22.0002 6.01318C21.3863 6.01318 20.7818 6.1643 20.2402 6.45318Z"
                        fill="#2F307D"
                      />
                    </svg>
                  </div>
                  <div>
                    <h2 className="text-sm font-bold font-primary">
                      ADULT & KIDS COURSES
                    </h2>
                    <p className="text-sm font-text">
                      Providing both adults and kids public speaking programmes
                      for a curated experience
                    </p>
                  </div>
                </div>
                <div className="flex flex-row space-x-3 lg:w-1/2">
                  <div className="min-h-[60px] min-w-[60px] border-2 border-[#f2f2f2] bg-white">
                    <svg
                      className="mx-auto my-1"
                      width="38"
                      height="40"
                      viewBox="0 0 38 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.33317 17.3335H9.83317V30.1668H4.33317V17.3335ZM16.2498 17.3335H21.7498V30.1668H16.2498V17.3335ZM0.666504 33.8335H37.3332V39.3335H0.666504V33.8335ZM28.1665 17.3335H33.6665V30.1668H28.1665V17.3335ZM18.9998 0.833496L0.666504 10.0002V13.6668H37.3332V10.0002L18.9998 0.833496Z"
                        fill="#2F307D"
                      />
                    </svg>
                  </div>
                  <div>
                    <h2 className="text-sm font-bold font-primary">
                      LEARNER-CENTRIC
                    </h2>
                    <p className="text-sm font-text">
                      Our learning experience is curated for and focused on you
                      as priority
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex flex-col mt-5 mb-10 lg:my-10 lg:flex-row">
                <div className="flex flex-row my-5 space-x-3 lg:my-0 lg:w-1/2">
                  <div className="min-h-[60px] min-w-[60px] border-2 border-[#f2f2f2] bg-white">
                    <svg
                      className="mx-auto my-1"
                      width="44"
                      height="44"
                      viewBox="0 0 44 44"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M34.8333 9.16667H31.1667V5.5H12.8333V9.16667H9.16667C7.15 9.16667 5.5 10.8167 5.5 12.8333V14.6667C5.5 19.3417 9.02 23.155 13.5483 23.7233C14.1281 25.0972 15.0337 26.3092 16.1868 27.2546C17.3399 28.2001 18.7059 28.8506 20.1667 29.15V34.8333H12.8333V38.5H31.1667V34.8333H23.8333V29.15C25.2941 28.8506 26.6601 28.2001 27.8132 27.2546C28.9663 26.3092 29.8719 25.0972 30.4517 23.7233C34.98 23.155 38.5 19.3417 38.5 14.6667V12.8333C38.5 10.8167 36.85 9.16667 34.8333 9.16667ZM9.16667 14.6667V12.8333H12.8333V19.8367C10.7067 19.0667 9.16667 17.05 9.16667 14.6667ZM22 25.6667C18.975 25.6667 16.5 23.1917 16.5 20.1667V9.16667H27.5V20.1667C27.5 23.1917 25.025 25.6667 22 25.6667ZM34.8333 14.6667C34.8333 17.05 33.2933 19.0667 31.1667 19.8367V12.8333H34.8333V14.6667Z"
                        fill="#2F307D"
                      />
                    </svg>
                  </div>
                  <div>
                    <h2 className="text-sm font-bold font-primary">
                      WORLD CHAMPION QUALITY
                    </h2>
                    <p className="text-sm font-text">
                      Receive our World Champion endorsement through our
                      in-house certificates/awards
                    </p>
                  </div>
                </div>
                <div className="flex flex-row space-x-3 lg:w-1/2">
                  <div className="min-h-[60px] min-w-[60px] border-2 border-[#f2f2f2] bg-white">
                    <svg
                      className="mx-auto my-1"
                      width="44"
                      height="44"
                      viewBox="0 0 44 44"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.7468 25.0982L22.0002 21.8715L26.2352 25.0982L24.6218 19.8732L28.8752 16.4998H23.6685L22.0002 11.3482L20.3318 16.4998H15.1252L19.3602 19.8732L17.7468 25.0982ZM36.6668 18.3332C36.6668 10.2298 30.1035 3.6665 22.0002 3.6665C13.8968 3.6665 7.3335 10.2298 7.3335 18.3332C7.3335 22.0548 8.72683 25.4282 11.0002 28.0132V42.1665L22.0002 38.4998L33.0002 42.1665V28.0132C35.3641 25.3429 36.6685 21.8995 36.6668 18.3332ZM22.0002 7.33317C28.0685 7.33317 33.0002 12.2648 33.0002 18.3332C33.0002 24.4015 28.0685 29.3332 22.0002 29.3332C15.9318 29.3332 11.0002 24.4015 11.0002 18.3332C11.0002 12.2648 15.9318 7.33317 22.0002 7.33317ZM22.0002 34.8332L14.6668 36.7032V31.0198C16.8302 32.2665 19.3235 32.9998 22.0002 32.9998C24.6768 32.9998 27.1702 32.2665 29.3335 31.0198V36.7032L22.0002 34.8332Z"
                        fill="#2F307D"
                      />
                    </svg>
                  </div>
                  <div>
                    <h2 className="text-sm font-bold font-primary">
                      ONLINE & ONSITE
                    </h2>
                    <p className="text-sm font-text">
                      We have experience in both onsite and online sessions to
                      suit your needs
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <NavLink
                  to="/courses-and-programs"
                  className="px-10 py-4 text-2xl text-white uppercase bg-btnColor font-primary hover:bg-iconColor2"
                >
                  Get Started
                </NavLink>
              </div>
            </div>
            <div className="w-full mt-10 lg: lg:w-1/2">
              <div className="darren-bg">
                <div className="relative">
                  <div className="absolute mx-auto border-8 border-white rounded-full left-3 top-64 h-36 w-36 bg-iconColor2 hover:bg-btnColor">
                    <a href="#promotion-video">
                      <span className="text-white material-symbols-rounded text-9xl">
                        {" "}
                        play_arrow{" "}
                      </span>
                    </a>
                  </div>
                </div>
                <img
                  className="p-5 mx-auto"
                  src="images/darren-tay-wen-jie-2.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* About Us Section */}
      <section id="about-us" className="relative z-50 mt-[-100px]">
        <div className="rounded-3xl bg-[#f5f5fc] lg:mx-20">
          <div className="container py-10 mx-auto">
            <div className="px-1 pt-5 lg:pt-24">
              <h1 className="text-4xl font-bold text-center uppercase font-primary text-primary lg:text-5xl">
                find out more about us!
              </h1>
              <p className="px-1 my-10 text-xl text-center font-text text-textColor lg:px-24 lg:text-2xl">
                If you are still deciding on the best option for your public
                speaking/communication training needs, take some time to find
                out more about our trainers, our content, and our
                courses/programs!
              </p>
              <hr className="my-4 mx-auto h-1 w-48 rounded border-0 bg-[#8598AF]" />
              <div>
                <svg
                  className="mx-auto"
                  width="37"
                  height="19"
                  viewBox="0 0 37 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.965575 0.0283205L36.9214 0.0283203L18.9435 18.0062L0.965575 0.0283205Z"
                    fill="#8598AF"
                  />
                </svg>
              </div>
            </div>
            <div className="flex flex-col justify-center my-10 col lg:flex-row lg:space-x-4">
              <div className="px-10 py-10 m-4 bg-white rounded-xl lg:m-0">
                <div>
                  <img
                    className="mx-auto"
                    src="images/our-qualified-trainers.png"
                    alt=""
                  />
                </div>
                <div className="py-4 mx-auto w-72">
                  <h3 className="text-xl font-bold text-center font-primary">
                    OUR QUALIFIED TRAINERS
                  </h3>
                  <p className="py-2 text-center font-text">
                    Check out our qualified Trainers, as trained by our Founder,
                    Mr Darren Tay
                  </p>
                </div>
                <div className="py-2 text-center">
                  <NavLink
                    to="/senior-trainers-trainers"
                    className="px-3 py-2 text-white bg-btnColor"
                  >
                    LEARN MORE
                  </NavLink>
                </div>
              </div>

              <div className="px-10 py-10 m-4 bg-white rounded-xl lg:m-0 ">
                <div>
                  <img
                    className="mx-auto"
                    src="images/our-youtube-channel.png"
                    alt=""
                  />
                </div>
                <div className="py-4 mx-auto w-72">
                  <h3 className="text-xl font-bold text-center font-primary">
                    OUR YOUTUBE CHANNEL
                  </h3>
                  <p className="py-2 text-center font-text">
                    Watch our videos for free, valuable public speaking tips and
                    past speeches
                  </p>
                </div>
                <div className="py-2 text-center">
                  <NavLink
                    to="https://www.youtube.com/playlist?list=PLRtJ94vOqCgWkGqa4uwpZo_ZcR1Cmqz-l"
                    className="px-3 py-2 text-white bg-btnColor"
                  >
                    LEARN MORE
                  </NavLink>
                </div>
              </div>

              <div className="px-10 py-10 m-4 bg-white rounded-xl lg:m-0">
                <div>
                  <img
                    className="mx-auto"
                    src="images/our-courses-program.png"
                    alt=""
                  />
                </div>
                <div className="py-4 mx-auto w-72">
                  <h3 className="text-xl font-bold text-center font-primary">
                    OUR COURSES & PROGRAMS
                  </h3>
                  <p className="py-2 text-center font-text">
                    Read about our quality, recognised public
                    speaking/communication courses
                  </p>
                </div>
                <div className="py-2 text-center">
                  <NavLink
                    to="/courses-and-programs"
                    className="px-3 py-2 text-white bg-btnColor"
                  >
                    LEARN MORE
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Media Releases Section --> */}
      <section id="media-releases">
        <div className="p-2 mt-20 lg:my-32 lg:p-0">
          <div className="container mx-auto">
            <h2 className="py-4 text-3xl font-bold uppercase font-primary text-primary lg:text-4xl">
              Media Releases
            </h2>
            <hr className="h-1 w-24 rounded border-0 bg-[#8598AF]" />
            <p className="my-5 text-2xl leading-9 font-text text-textColor">
              Hear about us from the local and international media - from news
              updates to interviews with our Founder, World Champion of Public
              Speaking 2016, Darren Tay! Read on to find out more:
            </p>
            <div className="flex flex-col items-center justify-between space-y-10 lg:flex-row lg:space-x-10 lg:space-y-0">
              <div className="w-full lg:max-w-1/2 lg:w-1/2">
                <iframe
                  src="https://www.youtube.com/embed/mfO3vU5qa7o"
                  frameborder="0"
                  allowfullscreen
                  className="w-full rounded-lg aspect-video"
                ></iframe>
              </div>
              <div className="w-full lg:max-w-1/2 lg:w-1/2">
                <iframe
                  src="https://www.youtube.com/embed/zpnQqjkd6Ps"
                  frameborder="0"
                  allowfullscreen
                  className="w-full rounded-lg aspect-video"
                ></iframe>
              </div>
            </div>
            <div className="mx-auto my-20 text-center">
              <NavLink
                className="px-12 py-3 text-xl font-bold text-white uppercase rounded-md bg-btnColor font-primary"
                to="/media"
              >
                View More
              </NavLink>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Milestone Section --> */}
      <section id="milestone">
        <img src="/images/milestone-bg-top.png" alt="" />
        <div className="bg-[#fff6eb]">
          <div className="container px-2 py-20 mx-auto">
            <div className="mb-10">
              <h1 className="text-3xl font-bold uppercase font-primary text-primary lg:text-4xl">
                Our Milestones
              </h1>
              <hr className="my-4 h-1 w-48 rounded border-0 bg-[#8598AF]" />
            </div>
            <div className="space-y-10">
              <div className="w-full lg:w-3/4">
                <h3 className="text-xl font-bold uppercase font-primary text-cyan-700">
                  2009 - Public Speaking academy was found!
                </h3>
                <div className="border-2 border-dashed rounded-lg border-primary">
                  <p className="p-3">
                    Our journey began in 2009 - the Public Speaking Academy was
                    started by our Founder, Mr Darren Tay, with a vision of
                    providing practical and professional training in the art of
                    communication skills and public speaking in Singapore, and
                    beyond. The Academy continued to gain international and
                    local recognition, catering to the specific learning needs
                    of our learners, with programmes ranging from weekly public
                    speaking sessions for kids and adults to intensive boot
                    camps/masterclasses to hone our participants’ repertoire of
                    public speaking skills.
                  </p>
                </div>
              </div>

              <div className="w-full lg:ml-40 lg:w-3/4 xl:ml-72">
                <h3 className="text-xl font-bold uppercase font-primary text-cyan-700">
                  2010 - We started the national public speaking competition
                </h3>

                <div className="border-2 border-dashed rounded-lg border-primary">
                  <p className="p-3">
                    The Public Speaking Academy continues to contribute to
                    community-building efforts by starting the inaugural
                    National Public Speaking Competition - a national-level
                    public speaking competition for young speakers in the
                    elementary/primary/secondary school level. This continued to
                    be a highly-anticipated annual competition, in fostering an
                    environment for young speakers to meet and challenge
                    themselves.
                  </p>
                </div>
              </div>

              <div className="w-full lg:w-3/4">
                <h3 className="text-xl font-bold uppercase font-primary text-cyan-700">
                  2016 - our founder clinched the world championship of public
                  speaking
                </h3>
                <div className="border-2 border-dashed rounded-lg border-primary">
                  <p className="p-3">
                    Darren Tay, Founder of Public Speaking Academy, clinched the
                    top honour of the 2016 Toastsmasters International World
                    Champion of Public Speaking, against more than 30,000
                    competitiors around the world (as the youngest and first
                    Singaporean champion then!). Darren continues to apply the
                    success-techniques for public speaking in the programmes
                    with Public Speaking Academy (for kids, adults, corporate
                    bodies, schools!)
                  </p>
                </div>
              </div>

              <div className="w-full lg:ml-40 lg:w-3/4 xl:ml-72">
                <h3 className="text-xl font-bold font-primary text-cyan-700">
                  2017 - Singapore’s Largest public speaking competition
                </h3>
                <div className="border-2 border-dashed rounded-lg border-primary">
                  <p className="p-3">
                    As a leader in public speaking, Public Speaking Academy
                    continues its community-building efforts, with our National
                    Public Speaking Competition gaining the honour in the
                    Singapore Book of Records as the largest public speaking
                    competition in Singapore! This was an effort supported by
                    the educational institutions, contestants, and the
                    organizing team!
                  </p>
                </div>
              </div>

              <div className="w-full lg:w-3/4">
                <h3 className="text-xl font-bold uppercase font-primary text-cyan-700">
                  2018 to 2019 - we expanded our home!
                </h3>
                <div className="border-2 border-dashed rounded-lg border-primary">
                  <p className="p-3">
                    From our humble beginnings at our SAFRA Tampines and Hougang
                    Branch, our operations grew to cover more of Singapore as we
                    expand to our Bukit Timah (Coronation Plaza) and Parkway
                    Centre Branch. (Parkway Centre Branch has since merged with
                    our expanded SAFRA Tampines Branch). This is part of our
                    commitment to deliver quality public speaking training to
                    the wider community of speakers in Singapore!
                  </p>
                </div>
              </div>

              <div className="w-full lg:ml-40 lg:w-3/4 xl:ml-72">
                <h3 className="text-xl font-bold uppercase font-primary text-cyan-700">
                  2019 to 2020 - Public speaking goes online
                </h3>
                <div className="border-2 border-dashed rounded-lg border-primary">
                  <p className="p-3">
                    2019 will be remembered as the year COVID-19 hits - as
                    leaders in the industry, Public Speaking Academy continued
                    to find ways to deliver public speaking training to our
                    communicators out there. We ventured online and expanded to
                    offer global programmes for those who were unable to join us
                    in Singapore, leveraging technology, video-conferencing, and
                    novel learning methodologies to deliver quality public
                    speaking training.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img className="" src="/images/milestone-bg-bottom.png" alt="" />
      </section>
      {/* <!-- Express to Impress Section --> */}
      <section id="express">
        <div className="container flex flex-col items-center mx-auto my-20 lg:my-40 lg:flex-row lg:space-x-10">
          <div className="w-full p-2 py-10 lg:w-1/2">
            <img src="images/Express_to_Impress-2.png" alt="" />
          </div>
          <div className="w-full p-2 lg:w-1/2">
            <h2 className="text-2xl font-bold uppercase font-primary text-primary lg:text-4xl">
              WE help you ``EXPRESS TO IMPRESS``
            </h2>
            <hr className="my-4 h-1 w-48 rounded border-0 bg-[#8598AF]" />
            <p className="text-xl font-text text-textColor">
              At Public Speaking Academy, we train speakers and communicators to
              express themselves and impress others. We do this in multiple
              communication domains: Public Speaking, Negotiations, Persuasion,
              Debating, Inter-personal Skills, Interviews, Linguistic Mastery,
              Enrichment and National Examinations (e.g., Project Work, Oral
              Presentation). We do this for individuals, schools, corporate
              organisations - with each module customized to meet our clients’
              needs. Are you ready express and impress?
            </p>
          </div>
        </div>
      </section>
      {/* <!-- Testimonials --> */}
      <section id="testimonial">
        <div className="mx-2 bg-gray-100 rounded-3xl lg:mx-20">
          <div className="container py-10 mx-auto">
            <div className="pt-10 lg:pt-24">
              <h1 className="text-4xl font-bold text-center uppercase font-primary text-primary lg:text-5xl">
                Testimonials
              </h1>
              <p className="my-6 text-2xl text-center font-text text-textColor lg:my-10 lg:px-24">
                See What Our Students & Clients Saying About us
              </p>
              <hr className="my-4 mx-auto h-1 w-48 rounded border-0 bg-[#8598AF]" />
              <div>
                <svg
                  className="mx-auto"
                  width="37"
                  height="19"
                  viewBox="0 0 37 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.965575 0.0283205L36.9214 0.0283203L18.9435 18.0062L0.965575 0.0283205Z"
                    fill="#8598AF"
                  />
                </svg>
              </div>
            </div>
            <div className="flex flex-col items-center justify-between lg:flex-row">
              <div className="w-full py-10 mx-auto lg:w-1/2">
                <img
                  className="mx-auto"
                  src="/images/Testimonials.png"
                  alt=""
                />
              </div>
              <div className="w-full lg:w-1/2">
                <div>
                  <div className="mx-auto rounded-tl-[50px] rounded-bl-[50px] rounded-br-[50px] bg-iconColor2 py-10">
                    <svg
                      className="ml-5 opacity-50"
                      width="80"
                      height="80"
                      viewBox="0 0 80 80"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M60.0002 56.6666H50.0002L43.3335 43.3333V23.3333H63.3335V43.3333H53.3335L60.0002 56.6666ZM33.3335 56.6666H23.3335L16.6668 43.3333V23.3333H36.6668V43.3333H26.6668L33.3335 56.6666Z"
                        fill="#fff"
                      />
                    </svg>
                    <Swiper
                      navigation={{
                        prevEl: ".prev",
                        nextEl: ".next",
                      }}
                      cssMode={true}
                      modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                      className="testimonial"
                    >
                      <SwiperSlide>
                        <div className="px-10 py-2 text-white font-text">
                          <h3 className="mb-3 text-xl font-bold font-text">
                            Darren’s Methods Have Opened Up Multiple
                            Opportunities For Me.
                          </h3>
                          <p className="text-lg">
                            I wanted to overcome my fear of public speaking and
                            become better at getting my ideas across to a large
                            audience. After going through the training, I have
                            been able to deliver impactful messages across
                            various industry and startup conferences in
                            Singapore, India and Sri Lanka. I have received
                            sizeable press coverage and media recognition for my
                            public speaking. Darren’s methods and practice
                            sessions have really opened up multiple public
                            speaking opportunities for me. Even with smaller
                            audiences, it has enabled me to deliver better
                            presentations and has helped make my messaging
                            significantly more impactful.
                          </p>
                          <h5 className="mt-3 text-2xl font-primary">Thilan</h5>
                          <p className="uppercase font-primary">
                            Co-founder of Create Lab (startup)
                          </p>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="px-10 py-2 text-white font-text">
                          <h3 className="mb-3 text-xl font-bold font-text">
                            The Structure Helps Me Be Equipped to Handle
                            Different Situations.
                          </h3>
                          <p className="text-lg">
                            Before I went to Darren’s public speaking training,
                            my speeches or any impromptu times I need to speak I
                            usually feel really insecure. Most of the times, I
                            just speak whatever comes to my mind, usually appear
                            all over the place. Now… I felt more confident when
                            i have to speak in front of a crowd. The structure
                            and tips help me be more equipped to handle
                            different situations.
                          </p>
                          <h5 className="mt-3 text-2xl font-primary">Hazel</h5>
                          <p className="uppercase font-primary">
                            Millennial Life Coach & Financial Consultant
                          </p>
                        </div>
                      </SwiperSlide>

                      <SwiperSlide>
                        <div className="px-10 py-2 text-white font-text">
                          <h3 className="mb-3 text-xl font-bold font-text">
                            More Confident in Speaking & Expressing Myself As A
                            Person.
                          </h3>
                          <p className="text-lg">
                            I am more confident in terms of me speaking as well
                            as expressing myself as a person. This has enabled
                            me to meet up with different types of people and be
                            more comfortable and be more aware of their body
                            language when speaking to them. Generally, it has
                            also opened up new opportunities in my life and made
                            me a better person.
                          </p>
                          <h5 className="mt-3 text-2xl font-primary">Perry</h5>
                          <p className="uppercase font-primary">
                            Events Executive
                          </p>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="px-10 py-2 text-white font-text">
                          <p className="text-lg">
                            PSA of Mr. Darren Tay was discovered when it’s
                            advertisement popped up on my FB home page and I
                            thought to enroll my daughter, Malak, in 2020. Since
                            then we have not looked back and continued the
                            course for 2 whole years even though the duration is
                            one year. Malak had stage fright prior to joining
                            PSA. We love the way PSA classes are conducted with
                            Kahoot games. She volunteers to do presentations in
                            class which shows me how far she has come from the
                            shy girl who always said no to go in front of an
                            audience. Thank you Mr. Darren Tay and team for the
                            guidance and training you give to today’s
                            generation. Best wishes!
                          </p>
                          <h5 className="mt-3 text-2xl font-primary">
                            Aminath Shabeena
                          </h5>
                          <p className="uppercase font-primary">
                            Mother of Malak
                          </p>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="px-10 py-2 text-white font-text">
                          <p className="mb-3 text-lg">
                            It was the most enjoyable course I have attended. I
                            recommend the WCCP course to anyone interested in
                            improving their public speaking or communication
                            skills.
                            <p className="mb-3 text-lg">
                              In addition to covering a range of speaking
                              situations with practical techniques that can be
                              easily applied, the real gem is the opportunity to
                              practice the techniques in a very safe environment
                              and to learn from each other.
                            </p>
                            <p className="mb-3 text-lg">
                              I look forward to attending each lesson to
                              practice and improve my communication skills.
                            </p>
                            <p className="mb-3 text-lg">
                              Kudos to Constance and the team at Public Speaking
                              Academy!
                            </p>
                          </p>
                          <h5 className="mt-3 text-2xl font-primary">
                            Axel Low
                          </h5>
                          <p className="uppercase font-primary">
                            WCCP graduate
                          </p>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="px-10 py-2 text-white font-text">
                          <p className="text-lg">
                            Enrolling myself in the World Champion Certification
                            Programme (WCCP) was one of the best decisions I
                            made thus far as it has shaped me into a stronger
                            and confident speaker.
                            <br />
                            The routine of discovering and learning numerous
                            speech styles and practicing them at the end of the
                            lesson every week pushed me to think fast and be
                            more succinct with my presentation, while retaining
                            the key and significant points.
                            <br />
                            Needless to say, the guidance and support given by
                            my coach, Mr Imran surely made the 12-week-long
                            journey a pleasant one but he did not hesitate in
                            giving me his constructive criticism and area for
                            improvements whenever it was necessary. That was a
                            quality I appreciated about him.
                            <br />
                            All in all, if you wish to hone your communication
                            skills or simply explore the area of public
                            speaking, PSA will not disappoint you!
                          </p>
                          <h5 className="mt-3 text-2xl font-primary">
                            Shivani Pandey
                          </h5>
                          <p className="uppercase font-primary">
                            WCCP graduate
                          </p>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="px-10 py-2 text-white font-text">
                          <p className="text-lg">
                            Before I started attending PSA’s classes, I was an
                            introvert who had very Low self esteem. During my
                            first class, I spoke with a lot of hesitation and
                            swayed from side to side. However, after going
                            though about a year of public speaking training, my
                            became more confident in presenting and learnt how
                            to speak confidently. Using hand gestures while
                            speaking casually became very natural for me. This
                            has allowed me to present well in school
                            environments and communicate my ideas effectively.
                            Plan to participate in the National Public Speaking
                            Competition next year.
                          </p>
                          <h5 className="mt-3 text-2xl font-primary">
                            Kerwin Yu
                          </h5>
                          <p className="uppercase font-primary">
                            SEP (kids program) graduate
                          </p>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="px-10 py-2 text-white font-text">
                          <p className="text-lg">
                            Mr Tay's classes are super effective and enjoyable.
                            I looked forward to his lessons every week and it
                            really helped me grow more confident and proactive
                            as a person. He helped me to develop my public
                            speaking skills and to present myself confidently in
                            front of a big crowd. Today, I can even dance and
                            act in front of big audiences! Thanks Mr Tay!
                          </p>
                          <h5 className="mt-3 text-2xl font-primary">
                            Sinead Wong
                          </h5>
                          <p className="uppercase font-primary">
                            SEP (kids program) graduate
                          </p>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="px-10 py-2 text-white font-text">
                          <p className="text-lg">
                            I think that this experience at the Public Speaking
                            Academy was truly wonderful. I used to be shy and
                            did not dare to voice my opinions, but now I am able
                            to confidently share my thoughts. I learnt so many
                            techniques (eg. PEEL, SLE ) and they have benefited
                            me in many ways. I could pass majority of my
                            interviews with these techniques. I would highly
                            recommend to go for a class at the Public Speaking
                            Academy!
                          </p>
                          <h5 className="mt-3 text-2xl font-primary">
                            Chloe Lim
                          </h5>
                          <p className="uppercase font-primary">
                            SEP (kids program) graduate, 2019 National Public
                            Speaking Competition Top 4 winner
                          </p>
                        </div>
                      </SwiperSlide>

                      <div className="flex mt-5 ml-20 space-x-5">
                        <div className="cursor-pointer prev">
                          <span className="material-symbols-rounded rounded-md border-2 border-dashed bg-[#FFE4D5] p-3 text-4xl text-iconColor2 hover:border-red-100 hover:bg-iconColor2 hover:text-white">
                            arrow_back
                          </span>
                        </div>
                        <div className="cursor-pointer next">
                          <span className="material-symbols-rounded rounded-md border-2 border-dashed bg-[#FFE4D5] p-3 text-4xl text-iconColor2 hover:border-red-100 hover:bg-iconColor2 hover:text-white">
                            arrow_forward
                          </span>
                        </div>
                      </div>
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pb-10 lg:pb-20"></div>
        </div>
      </section>
      {/* <!-- Latest Blog --> */}
      <section id="latest-blog">
        <div className="container mx-auto">
          <div className="pt-24">
            <h1 className="text-4xl font-bold text-center uppercase font-primary text-primary lg:text-5xl">
              OUR LATEST BLOG
            </h1>
            <p className="my-5 text-2xl text-center font-text text-textColor">
              Read Our Latest Blogs and Posts
            </p>
            <div>
              <svg
                className="mx-auto"
                width="37"
                height="19"
                viewBox="0 0 37 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.965575 0.0283205L36.9214 0.0283203L18.9435 18.0062L0.965575 0.0283205Z"
                  fill="#8598AF"
                />
              </svg>
            </div>
          </div>
          <div className="flex flex-col px-4 my-10 space-y-10 lg:flex-row lg:space-y-0 lg:space-x-10 lg:px-0">
            <div className="max-w-[460px] rounded-xl border-solid border-gray-200 bg-white p-4 shadow-md shadow-gray-400">
              <img
                className="h-auto w-[427px] rounded-xl"
                src="/images/public-speaking-course-blog.png"
                alt=""
              />
              <div className="flex items-center justify-between pt-2">
                <div className="font-medium uppercase font-primary text-iconColor2">
                  John Doe
                </div>
                <div className="flex space-x-1 font-medium font-text text-iconColor2">
                  <span className="material-symbols-rounded">
                    {" "}
                    calendar_month{" "}
                  </span>
                  <span className="font-medium uppercase font-primary">
                    20 July 2022
                  </span>
                </div>
              </div>
              <h2 className="my-6 text-xl font-medium text-purple-700 font-primary">
                Recipe for Success – What Goes Into a Public Speaking Training
                Programme for Kids?
              </h2>
              <div className="py-6">
                <NavLink
                  className="px-4 py-2 font-bold text-purple-700 uppercase border-2 border-purple-700 border-solid rounded-lg hover:bg-purple-700 hover:text-white"
                  to="/recipe-for-success-what-goes-into-a-public-speaking-training-programme-for-kids"
                >
                  Read More
                </NavLink>
              </div>
            </div>
            <div className="max-w-[460px] rounded-xl border-solid border-gray-200 bg-white p-4 shadow-md shadow-gray-400">
              <img
                className="h-auto w-[427px] rounded-xl"
                src="/images/public-speaking-course-blog.png"
                alt=""
              />
              <div className="flex items-center justify-between pt-2">
                <div className="font-medium uppercase font-primary text-iconColor2">
                  John Doe
                </div>
                <div className="flex space-x-1 font-medium font-text text-iconColor2">
                  <span className="material-symbols-rounded">
                    {" "}
                    calendar_month{" "}
                  </span>
                  <span className="font-medium uppercase font-primary">
                    20 July 2022
                  </span>
                </div>
              </div>
              <h2 className="my-6 text-xl font-medium text-purple-700 font-primary">
                Recipe for Success – What Goes Into a Public Speaking Training
                Programme for Kids?
              </h2>
              <div className="py-6">
                <NavLink
                  className="px-4 py-2 font-bold text-purple-700 uppercase border-2 border-purple-700 border-solid rounded-lg hover:bg-purple-700 hover:text-white"
                  to="/recipe-for-success-what-goes-into-a-public-speaking-training-programme-for-kids"
                >
                  Read More
                </NavLink>
              </div>
            </div>
            <div className="max-w-[460px] rounded-xl border-solid border-gray-200 bg-white p-4 shadow-md shadow-gray-400">
              <img
                className="h-auto w-[427px] rounded-xl"
                src="/images/public-speaking-course-blog.png"
                alt=""
              />
              <div className="flex items-center justify-between pt-2">
                <div className="font-medium uppercase font-primary text-iconColor2">
                  John Doe
                </div>
                <div className="flex space-x-1 font-medium font-text text-iconColor2">
                  <span className="material-symbols-rounded">
                    {" "}
                    calendar_month{" "}
                  </span>
                  <span className="font-medium uppercase font-primary">
                    20 July 2022
                  </span>
                </div>
              </div>
              <h2 className="my-6 text-xl font-medium text-purple-700 font-primary">
                Recipe for Success – What Goes Into a Public Speaking Training
                Programme for Kids?
              </h2>
              <div className="py-6">
                <NavLink
                  className="px-4 py-2 font-bold text-purple-700 uppercase border-2 border-purple-700 border-solid rounded-lg hover:bg-purple-700 hover:text-white"
                  to="/recipe-for-success-what-goes-into-a-public-speaking-training-programme-for-kids"
                >
                  Read More
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Contact Form --> */}
      <section id="contaft-form">
        <div className="container mx-auto py-28">
          <div className="flex flex-col-reverse items-center justify-center lg:flex-row">
            <div className="w-full lg:w-1/2">
              <div>
                <div className="flex flex-col p-10 border-gray-200 rounded-3xl lg:max-w-xl lg:space-y-5 lg:border-4">
                  <h2 className="text-4xl font-bold text-center font-primary text-primary">
                    Come Reach Out To Us
                  </h2>
                  <p className="text-xl text-center font-text text-textColor">
                    Our Training Consultants are on hand to assist you with your
                    query/training needs
                  </p>
                  <form className="flex flex-col space-y-5" action="">
                    <input
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                      type="text"
                      placeholder="ENTER NAME"
                    />
                    <input
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                      type="email"
                      placeholder="ENTER EMAIL"
                    />
                    <input
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                      type="text"
                      placeholder="ENTER TELEPHONE"
                    />
                    <textarea
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                      name=""
                      id=""
                      cols="10"
                      rows="4"
                      placeholder="ENTER MESSAGE"
                    ></textarea>
                    <button
                      className="w-full rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 sm:w-auto"
                      type="submit"
                    >
                      SUBMIT
                    </button>
                  </form>
                </div>
              </div>
            </div>
            <div className="w-full p-10 bg-purple-400 lg:w-1/2 lg:rounded-l-3xl">
              <img className="" src="./images/appointment.png" alt="" />
            </div>
          </div>
        </div>
      </section>
      {/* Image Gallery and Media Platform */}

      <section id="gallery">
        <div className="bg-red-100 lg:mx-20 lg:rounded-t-3xl">
          <div className="container px-4 pb-24 mx-auto">
            <div className="flex items-center justify-center pt-10 space-x-10 lg:pt-24">
              <Swiper
                slidesPerView={1}
                spaceBetween={10}
                pagination={{
                  clickable: true,
                }}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <img
                    className="border-b-4 border-r-4 rounded-xl border-iconColor1"
                    src="/images/block-slider-1.jpg"
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className="border-b-4 border-r-4 rounded-xl border-iconColor1"
                    src="/images/block-slider-2.jpg"
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className="border-b-4 border-r-4 rounded-xl border-iconColor1"
                    src="/images/block-slider-3.jpg"
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className="border-b-4 border-r-4 rounded-xl border-iconColor1"
                    src="/images/block-slider-4.jpg"
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className="border-b-4 border-r-4 rounded-xl border-iconColor1"
                    src="/images/block-slider-5.jpg"
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className="border-b-4 border-r-4 rounded-xl border-iconColor1"
                    src="/images/block-slider-6.jpg"
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className="border-b-4 border-r-4 rounded-xl border-iconColor1"
                    src="/images/block-slider-7.jpg"
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className="border-b-4 border-r-4 rounded-xl border-iconColor1"
                    src="/images/block-slider-8.jpg"
                    alt=""
                  />
                </SwiperSlide>
              </Swiper>
            </div>
            {/* As Featured on Media Platform */}
            <div>
              <div className="mt-10 lg:mt-20">
                <h2 className="text-xl font-bold uppercase font-primary text-primary">
                  As featured on these Media Platforms
                </h2>
              </div>
              <div className="p-5 border-2 border-dashed rounded-lg border-iconColor2">
                <div className="flex justify-between">
                  <Swiper
                    slidesPerView={4}
                    spaceBetween={30}
                    slidesPerGroup={4}
                    loop={true}
                    loopFillGroupWithBlank={true}
                    pagination={{
                      clickable: true,
                    }}
                    navigation={true}
                    modules={[Pagination, Navigation]}
                    className="mySwiper"
                  >
                    <SwiperSlide>
                      <img
                        className="duration-300 grayscale hover:grayscale-0"
                        src="/images/media-1.jpg"
                        alt=""
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img
                        className="duration-300 grayscale hover:grayscale-0"
                        src="/images/media-2.jpg"
                        alt=""
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img
                        className="duration-300 grayscale hover:grayscale-0"
                        src="/images/media-3.jpg"
                        alt=""
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img
                        className="duration-300 grayscale hover:grayscale-0"
                        src="/images/media-4.jpg"
                        alt=""
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img
                        className="duration-300 grayscale hover:grayscale-0"
                        src="/images/media-5.jpg"
                        alt=""
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img
                        className="duration-300 grayscale hover:grayscale-0"
                        src="/images/media-6.jpg"
                        alt=""
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img
                        className="duration-300 grayscale hover:grayscale-0"
                        src="/images/media-7.jpg"
                        alt=""
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img
                        className="duration-300 grayscale hover:grayscale-0"
                        src="/images/media-8.jpg"
                        alt=""
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img
                        className="duration-300 grayscale hover:grayscale-0"
                        src="/images/media-9.jpg"
                        alt=""
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img
                        className="duration-300 grayscale hover:grayscale-0"
                        src="/images/media-10.jpg"
                        alt=""
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img
                        className="duration-300 grayscale hover:grayscale-0"
                        src="/images/media-11.jpg"
                        alt=""
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img
                        className="duration-300 grayscale hover:grayscale-0"
                        src="/images/media-12.jpg"
                        alt=""
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img
                        className="duration-300 grayscale hover:grayscale-0"
                        src="/images/media-13.jpg"
                        alt=""
                      />
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="modal" id="modal-read-more">
        <div className="w-11/12 max-w-5xl modal-box">
          <p className="py-4">
            Public speaking and effective communication skills are cornerstones
            of future success – these are timeless, recession-proof skills
            employers demand now. In the inevitable future of automation,
            technology in the world, your public speaking and communication
            skills ensure that the spotlight remains on you. Be it acing that
            high-impact business presentation, high-stakes interview or the
            primary/secondary oral examinations, the Public Speaking Academy is
            excited and ready to provide our dedicated and researched service in
            helping you attain excellent and effective communication skills.
          </p>
          <div className="modal-action">
            <a href="#" className="btn">
              Close
            </a>
          </div>
        </div>
      </div>
      <div className="modal" id="promotion-video">
        <div className="w-11/12 max-w-5xl modal-box">
          <div>
            <iframe
              src="https://www.youtube.com/embed/2d9VMNQMogg"
              frameborder="0"
              allowfullscreen
              className="w-full rounded-lg aspect-video"
            ></iframe>
          </div>
          <div className="modal-action">
            <a href="#" className="btn">
              Close
            </a>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
