import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import { NavLink } from "react-router-dom";

export default function RecipeForSuccess() {
  return (
    <>

      <section id="breadcrumb" className="bg-[#F5F5FC] py-1">
        <div className="container mx-auto">
          <div className="flex justify-between">
            <div>Recipe for Success – What Goes Into a Public Speaking Training Programme for Kids?</div>
            <div>Home / Blog</div>
          </div>
        </div>
      </section>

      <section id="block-1">
        <div class="container mx-auto py-20">
          <div class="flex pt-5 space-x-10">
            <div class="w-2/3">
              <h1 class="font-primary text-3xl font-bold text-primary text-center">
                Recipe for Success – What Goes Into a Public Speaking Training Programme for Kids?
              </h1>
              <div>
                <img
                  className="block mx-auto my-10"
                  src="/images/public-speaking-course-for-adults-public-speaking-courses-for-adults-public-speaking-for-adults-1-3-1024x576.png"
                  alt=""
                />

                <p class="py-2 font-text text-lg">
                  If this is your first time considering a public speaking training programme for your kid, you may find it daunting to research and learn more
                  about what such courses entail. For the most part, e-mail brochures or marketing pages tend to set out the broad benefits. However, we
                  understand that as parents, you want to know what your child will be exploring in any such programme. The investment in time (and money!) to
                  develop your child’s soft skills should not be a blind process!
                </p>
                <p class="py-2 font-text text-lg">
                  In today’s article, we thought of sharing the inner workings of a public speaking programme, especially one that is tailored for young
                  communicators. Beyond the fluff and administrative details, the programme pedagogy can help you decide between the various training programmes
                  on offer. Whether it is a public speaking programme like ours or another enrichment programme elsewhere, you can be better equipped in
                  choosing the most appropriate course for your child.
                </p>
                <p class="py-2 font-text text-lg">
                  Here are three things we emphasise in crafting our public speaking training programmes for our young learners!
                </p>

                <h3 class="py-5 text-2xl font-bold italic font-primary">Build Rapport as Bridges</h3>
                <p class="py-2 font-text text-lg">
                  Even before the learning or exploration process begins, our training team find it important to create bridges with our young learners. This is
                  achieved through a bridge-building process – creating rapport with our students to create a safe, open, and constructive learning environment.
                  While some may hold the view that a child is unlikely to appreciate rapport-building, we believe that it is part of a communicator skillset to
                  understand the importance of conversation and relationship-building – especially at a young age!
                </p>
                <p class="py-2 font-text text-lg">
                  Our training team, for example, utilises a variety of pedagogical approaches to building the rapport bridge with our young communicators. From
                  the moment they step in, we begin an inquiry process – by unpacking their interests or even passion. A brief exchange can offer information
                  that can be used to encourage the learners during the subsequent activity. Another approach (e.g., in our 2-Day masterclass, S’Peak
                  Performance Programme) is to use storytelling based on the trainer’s personal and intimate experiences. This open and honest approach helps to
                  reassure the learners that the programme is interactive and engaging.
                </p>
                <img
                  className="my-10"
                  src="/images/public-speaking-course-for-adults-public-speaking-courses-for-adults-public-speaking-for-adults-2-3-1024x576.png"
                  alt=""
                />
                <h3 class="py-5 text-2xl font-primary italic font-bold">Step-by-Step; Hand-in-Hand</h3>

                <p class="py-2 font-text text-lg">
                  As young communicators, the learning curve may be steep and scary, especially if you are not used to public speaking. Some believe that
                  learning is achieved by throwing the students into the deep end (e.g., having them dive on stage to present); others prefer having the
                  students work on and master that one speech. We believe that the best approach is an incremental learning process, with guidance from the
                  trainers.
                </p>
                <p class="py-2 font-text text-lg">
                  For our programmes across Public Speaking Academy, we take a step-up approach in encouraging our young communicators. We believe that each
                  child should be allowed to develop at their own pace. So, for example, participants in our S’Peak Performance Programme do not jump directly
                  into a full speech presentation in front of the entire cohort. Instead, they apply specific techniques/components in break-out sessions (e.g.,
                  smaller group, bite-sized objective). This will culminate in a showcase presentation on the last day to apply what they have explored
                  throughout the programme!
                </p>
                <img
                  className="my-10"
                  src="/images/public-speaking-course-for-adults-public-speaking-courses-for-adults-public-speaking-for-adults-3-2-1024x576.png"
                  alt=""
                />
                <h3 class="py-5 text-2xl font-primary italic font-bold">Challenge Yourself to Find Your Voice</h3>
                <p class="py-2 font-text text-lg">
                  All that said, a challenging environment is also required to ensure that a young communicator pushes and expands their comfort zone. This is
                  the balance between following the learner’s pace but imbuing the motivation to improve their communication skills. We find that
                  objective-setting no longer becomes a fearful topic for our young learners as they see it as part of their growth as communicators (and how
                  they can apply the skills beyond the classroom!).
                </p>
                <p class="py-2 font-text text-lg">
                  Toeing the line between challenge and comfort can be achieved in three ways. First, young communicators can set out bite-sized objectives or
                  goals to accomplish. For example, instead of giving a laundry list of improvement points, we focus on sharing two key areas of improvement for
                  each exercise/activity. Second, have a dedicated mentor for the learning process. For example, our S’Peak Performance School Holiday Programme
                  is anchored by Public Speaking World Champion Darren Tay and his team of experienced Trainers who have already built rapport with the
                  students. Third, continue acknowledging the building on the learners’ interests. As unique individuals, our young communicators are likely to
                  be more open speaking about areas they are passionate about.
                </p>
                <p class="py-2 font-primary text-lg text-center font-bold">*** Our trainers’ view: “Seek for Progress instead of Perfection” ***</p>
                <img className="block mx-auto my-10" src="/images/comfort-zone.gif" alt="" />
                <h3 class="py-5 text-2xl font-primary italic font-bold">Your Child’s Public Speaking Journey …</h3>
                <p class="py-2 font-text text-lg">
                  Choosing the right enrichment program/ school holiday programme for your child can be a difficult process, especially if you are unsure about
                  how the programme runs. Our sharing today aims to shed light on the main principles that guide our training programmes (e.g., our S’Peak
                  Performance School Holiday Programme). We hope the points above have helped to shed some light on how our programmes are conducted!
                </p>
              </div>
            </div>
            <div className="w-1/3 px-5 pt-5 mb-10 border border-dashed rounded-lg">
              <Sidebar />
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
