import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import { NavLink } from "react-router-dom";

export default function Blog() {
  return (
    <>

      <section id="breadcrumb" className="bg-[#F5F5FC] py-1">
        <div className="container mx-auto">
          <div className="flex justify-between">
            <div>OUR Blog</div>
            <div>Home / Blog</div>
          </div>
        </div>
      </section>

      <section id="latest-blog">
        <div className="container mx-auto">
          <div className="pt-24">
            <h1 className="text-5xl font-bold text-center uppercase font-primary text-primary">OUR LATEST BLOG</h1>
            <p className="my-5 text-2xl text-center text-textColor font-text">Read Our Latest Blogs and Posts</p>
            <div>
              <svg className="mx-auto" width="37" height="19" viewBox="0 0 37 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.965575 0.0283205L36.9214 0.0283203L18.9435 18.0062L0.965575 0.0283205Z" fill="#8598AF" />
              </svg>
            </div>
          </div>
          <div className="flex my-10 space-x-10">
            <div className="max-w-[460px] bg-white border-gray-200 rounded-xl border-solid shadow-md shadow-gray-400 p-4">
              <img className="w-[427px] h-auto rounded-xl" src="/images/public-speaking-course-blog.png" alt="" />
              <div className="flex items-center justify-between pt-2">
                <div className="font-medium uppercase text-iconColor2 font-primary">admin</div>
                <div className="flex space-x-1 font-medium text-iconColor2 font-text">
                  <span className="material-symbols-rounded"> calendar_month </span>
                  <span className="font-medium uppercase font-primary">20 July 2022</span>
                </div>
              </div>
              <h2 className="my-6 text-xl font-medium text-purple-700 font-primary">
                Recipe for Success – What Goes Into a Public Speaking Training Programme for Kids?
              </h2>
              <div className="py-6">
                <NavLink
                  className="px-4 py-2 font-bold text-purple-700 uppercase border-2 border-purple-700 border-solid rounded-lg hover:bg-purple-700 hover:text-white"
                  to="/recipe-for-success-what-goes-into-a-public-speaking-training-programme-for-kids"
                >
                  Read More
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
